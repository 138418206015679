export const config = {
  FEATURE_FLAG_BANNER_EXPLORE_CTA_BUTTON: true,
  PREMIER_LOCATION_CAROUSEL_DESCRIPTION:
    'New MICHELIN Guide events, available exclusively for cardholders',
  PREMIER_LOCATION_CAROUSEL_ACTION_PATH: '/premier-location',
  PREMIER_LOCATION_CAROUSEL_ACTION_TEXT: 'Learn More',
  videoUrl:
    'https://d2p9w4ui8rp50l.cloudfront.net/m/17626fb60e5a0c40/mp4-COF-Dining-4x5-FB-Video.mp4',
  venueSortDirection: 'desc',
  permaLinks: {
    oyamel: '/venue/99313a09-0cb6-4aa9-9abf-93b299a3270f',
    'DCA-Capital-One-Landing': '/venue/ca9107d5-b375-4775-926c-0593934b86d0',
  },
};
