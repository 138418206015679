import CreditCard from './CreditCard';
import Divider from '../../components/overrides/Divider';
import { Grid } from '@material-ui/core';
import EditSaveButton from '../../components/core/EditSaveButton';
import BackButtonLayout from '../../components/core/BackButtonLayout';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useAuth } from '../../utils/useAuth';
import { useGetPaymentMethod } from './useGetPaymentMethod';
import { PAYMENT_METHOD_COMPONENT_TYPES } from './paymentComponentTypes';

const useStyles = makeStyles((theme) => ({
  paymentSelectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: theme.spacing(1),
  },
  paymentContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    width: '100%',
  },
  paymentDivider: {
    width: '100%',
    margin: theme.spacing(3, 0, 3),
  },
}));

const DefaultPaymentEditView = ({
  handleBack,
  title,
  type,
  handleSavePayment,
  saveButtonText,
  saveButtonHelperText,
}) => {
  const classes = useStyles();
  const { user } = useAuth();
  const [selectedPayment, setSelectedPayment] = useState('');
  const [selectedDefault, setSelectedDefault] = useState(null);
  const [shouldDisableSaveButton, setShouldDisableSaveButton] = useState(true);
  const currentPaymentAccount = useGetPaymentMethod(type);

  const handleSelectPayment = (value) => {
    setSelectedPayment(value);
    if (
      value.associatedAccountReferenceId !==
      selectedPayment.associatedAccountReferenceId
    )
      setSelectedDefault(null);
  };

  const handleSave = () => {
    // Cloning selectedPayment to prevent Redux bug where it also saves isDefaultPaymentEdited to user state
    const selectPaymentClone = {
      ...selectedPayment,
      isDefaultPaymentEdited: !!selectedDefault,
    };
    handleSavePayment(selectPaymentClone);
  };

  const handleSelectDefault = (value) => {
    if (value === selectedDefault) {
      setSelectedDefault(null);
      return;
    }
    setSelectedDefault(value);
  };

  useEffect(() => {
    currentPaymentAccount && setSelectedPayment(currentPaymentAccount);
    setSelectedDefault(
      currentPaymentAccount?.isDefaultPaymentEdited
        ? currentPaymentAccount.associatedAccountReferenceId
        : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedPayment && currentPaymentAccount) {
      const isPaymentMethodUnchanged =
        selectedPayment.associatedAccountReferenceId ===
        currentPaymentAccount.associatedAccountReferenceId;
      const isDefaultPaymentEditedAndUnchanged =
        currentPaymentAccount.isDefaultPaymentEdited &&
        currentPaymentAccount.associatedAccountReferenceId === selectedDefault;
      const isDefaultPaymentUnchanged =
        !currentPaymentAccount.isDefaultPaymentEdited &&
        selectedDefault === null;

      if (
        (isPaymentMethodUnchanged && isDefaultPaymentEditedAndUnchanged) ||
        (isPaymentMethodUnchanged && isDefaultPaymentUnchanged)
      ) {
        setShouldDisableSaveButton(true);
        return;
      }
    }

    setShouldDisableSaveButton(false);
  }, [selectedDefault, currentPaymentAccount, selectedPayment]);

  return (
    <BackButtonLayout
      back={handleBack}
      title={title}
      role="alert"
      aria-live="polite">
      <div className={classes.paymentSelectionContainer} role="radiogroup">
        {user.associatedAccounts.map((account) => {
          const isDefaultPayment =
            account.associatedAccountReferenceId ===
            user.c1DefaultPaymentMethodAccountId;

          return (
            <div
              key={`payment-container-${account.associatedAccountReferenceId}`}>
              <div className={classes.paymentContainer}>
                <CreditCard
                  selectedPayment={selectedPayment}
                  handleSelectPayment={handleSelectPayment}
                  account={account}
                  isDefaultPayment={isDefaultPayment}
                  isSingleCardUser={false}
                  checked={
                    selectedDefault === account.associatedAccountReferenceId
                  }
                  showCheckBox={
                    type === PAYMENT_METHOD_COMPONENT_TYPES.EDIT_VIEW &&
                    selectedPayment.associatedAccountReferenceId ===
                      account.associatedAccountReferenceId &&
                    selectedPayment.associatedAccountReferenceId !==
                      user.c1DefaultPaymentMethodAccountId
                  }
                  onSelect={handleSelectDefault}
                  type="editView"
                />
              </div>

              <Divider className={classes.paymentDivider} />
            </div>
          );
        })}
      </div>

      <Grid item xs={12}>
        <EditSaveButton
          onSave={handleSave}
          text={saveButtonText}
          accessibilityHelperText={saveButtonHelperText}
          disabled={shouldDisableSaveButton}
        />
      </Grid>
    </BackButtonLayout>
  );
};

export default DefaultPaymentEditView;
