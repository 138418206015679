import React, { useContext, createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
  actionGetUserByAuthCodeRequest,
  actionSetUser,
  actionSignoutUserRequest,
  actionUpdateUser,
} from '../store/User/UserAction';
import { generateRandomString, generateRandomUUID } from './random';
import { clearExpirationTimestamp } from './useIdleTimeout';
import { actionSetReservationPaymentRedirectFromStepUp } from '../store/Reservations/ReservationsAction';
import { ERROR_PAGE_TYPES } from './constants/ErrorPageTypes';
import { actionSetErrorType } from '../store/Error/ErrorAction';
import { useValidatedQueryParamString } from './useValidatedQueryParamString';
import {
  clearPermalinkLogin,
  clearReservationPageBackRoute,
  getPermalinkLogin,
} from './sessionStorageHelper';
import { getSilentRefreshInstance } from './SilentRefresh';
import { axiosInstance } from '../store/helpers';
import { MAINTENANCE_ROUTE } from '../store/apiRoutes';

export const AUTH_STATUS = Object.freeze({
  FAILED: 'failed',
  PENDING: 'pending',
  SUCCESS: 'success',
});

export const C1_CALLBACK_ERROR_CODES = Object.freeze({
  STEP_UP_GENERAL_ERROR: '8200',
  STEP_UP_ABORTED: '8300',
  STEP_UP_LOCKED_OUT: '8400',
});

const OAUTH_STATE_KEY = 'oauthState';
const REDIRECT_ROUTE_KEY = 'redirectRoute';

const setOauthState = () => {
  const oauthState = generateRandomString();
  sessionStorage.setItem(OAUTH_STATE_KEY, oauthState);
  return oauthState;
};

const getOauthState = () => sessionStorage.getItem(OAUTH_STATE_KEY);

const clearOauthState = () => {
  sessionStorage.removeItem(OAUTH_STATE_KEY);
};

const setRedirectRoute = (route) => {
  sessionStorage.setItem(REDIRECT_ROUTE_KEY, route);
};

const getRedirectRoute = () => sessionStorage.getItem(REDIRECT_ROUTE_KEY);

const clearRedirectRoute = () => {
  sessionStorage.removeItem(REDIRECT_ROUTE_KEY);
};

const getC1Config = () => {
  const c1BaseUrl = process.env.REACT_APP_C1_BASE_URL;
  const c1ClientId = process.env.REACT_APP_C1_CLIENT_ID;

  if (!c1BaseUrl || !c1ClientId) {
    throw new Error('REACT_APP_C1_BASE_URL or REACT_APP_C1_CLIENT_ID unset');
  }

  return {
    c1BaseUrl,
    c1ClientId,
  };
};

export const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

export const useAuthSearchParams = ({ search }) => {
  const searchParams = new URLSearchParams(search);
  const paramKeys = ['assertion', 'code', 'state', 'ccid', 'error'];

  return paramKeys.reduce(
    (accumulator, paramKey) => ({
      ...accumulator,
      [paramKey]: searchParams.get(paramKey),
    }),
    {}
  );
};

function useProvideAuth() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  const { errorType } = useSelector((state) => state.error);
  const authSearchParams = useAuthSearchParams(location);
  const search = useValidatedQueryParamString(location);

  useEffect(() => {
    if (user.session.status === AUTH_STATUS.FAILED) {
      console.error('useProviderAuth - authentication failed');

      dispatch(
        actionSetUser({
          session: {
            isAuthenticated: false,
            status: null,
          },
        })
      );
      clearOauthState();
    }
  }, [dispatch, user.session.status]);

  const getCCID = () => {
    const ccid = authSearchParams.ccid || `dining-${generateRandomUUID()}`;
    dispatch(actionUpdateUser({ session: { ccid } }));
    return ccid;
  };

  const signinWithJwt = (jwt) => {
    console.log('useProvideAuth.signinWithJwt');

    if (user.session.isAuthenticated || user.isRequestPending) {
      console.log(
        'useProvideAuth.signinWithJwt - already authenticated or request pending'
      );
      history.replace({ search });
      return user;
    }

    if (jwt == null) {
      console.log('useProvideAuth.signinWithJwt - no jwt and unauthenticated');
      history.push('/');
      return;
    }

    redirectToC1Consent({
      scope: 'openid',
      assertion: jwt,
    });
  };

  const redirectToOriginalRoute = () => {
    const redirectRoute = getPermalinkLogin() || getRedirectRoute();
    let result = redirectRoute?.split('?');
    const permalinkParameters = result && result[1] ? '?' + result.slice(1).join('?') : '';
    clearRedirectRoute();
    clearPermalinkLogin();
    history.replace({
      ...(result != null ? { pathname: result[0] } : {}),
      search: permalinkParameters || search,
    });
  };

  const intializeSilentRefreshIframe = () => {
    const silentRefresh = getSilentRefreshInstance();
    silentRefresh.setIsAuthenticated();
    silentRefresh.initializeTokenRefresh();
  };

  // TODO refactor to reduce cognitive complexity
  const signinWithAuthCode = () => {
    console.debug('useProvideAuth.signinWithAuthCode');

    const { code: authCode, state: stateParam, error } = authSearchParams;

    // as a security measure, immediately sign out the user due to too many attempts
    if (error === C1_CALLBACK_ERROR_CODES.STEP_UP_LOCKED_OUT) {
      history.replace('/sign-out');
      return;
    }

    const [stateValue, stateSuffix] = stateParam?.split('-') || [null, null];
    const isFromStepUp =
      stateSuffix === 'reserve' || stateSuffix === 'reserveModal';
    const isFromSilentRefresh = stateSuffix === 'refresh';
    const isFromRedirect = isFromSilentRefresh || isFromStepUp;

    // Error on initial login
    if (!isFromRedirect && error != null) {
      console.error('useProvideAuth.signinWithAuthCode - error:', error);
      dispatch(actionSetErrorType(ERROR_PAGE_TYPES.LOGIN.value));
      return;
    }

    if (user.session.isAuthenticated && !isFromRedirect) {
      console.log('useProvideAuth.signinWithAuthCode - already authenticated');
      redirectToOriginalRoute();
      return user;
    }

    if (stateValue != null) {
      const oauthState = getOauthState();
      clearOauthState();
      if (stateValue !== oauthState) {
        console.error('useProvideAuth.signinWithAuthCode: state invalid');
        history.push('/error');
        return;
      }
    }

    if (authCode != null || error != null) {
      // portal_reservation OAuth scope should only be added if booking reservation
      const scope = isFromStepUp ? 'openid portal_reservation' : 'openid';

      if (authCode != null) {
        dispatch(
          actionGetUserByAuthCodeRequest({
            authCode,
            scope,
            isFromStepUp,
            isFromSilentRefresh,
            ccid: user.session.ccid,
          })
        );
      }

      if (isFromStepUp) {
        if (authCode == null && error != null) {
          // Ensure the silent refresh iframe is initialized since the code in getUserByAuthCodeRequestHandler in UserSaga won't get called
          intializeSilentRefreshIframe();
        }
        // redirect to reservation confirm page from step up
        dispatch(
          actionSetReservationPaymentRedirectFromStepUp({
            stepUpErrorCode: error,
            isModal: stateSuffix === 'reserveModal',
          })
        );
        if (stateSuffix !== 'reserveModal') {
          history.push('/reserve');
        }
      } else {
        redirectToOriginalRoute();
      }

      return user;
    }

    redirectToC1Consent({
      scope: 'openid',
    });
  };

  const redirectToC1Challenge = (stateSuffix) => {
    console.log('useProvideAuth.redirectToChallenge');

    const { c1BaseUrl, c1ClientId } = getC1Config();

    const oauthState = setOauthState();

    const oauthSearchParams = new URLSearchParams({
      client_id: c1ClientId,
      redirect_uri: encodeURI(window.location.origin),
      scope: 'openid portal_reservation',
      state: `${oauthState}-${stateSuffix}`,
      login_hint: 'step_up',
    }).toString();

    const redirectUrl = `${c1BaseUrl}/oauth2/authorize?${oauthSearchParams}`;

    console.debug('Redirect to: ', redirectUrl);

    window.location = redirectUrl;
  };

  const redirectToC1Consent = async ({ assertion, scope }) => {
    if (errorType != null) return;
    try {
      await axiosInstance.get(MAINTENANCE_ROUTE);
    } catch (error) {
      if (error.response?.status === 503) {
        throw new Error(`${ERROR_PAGE_TYPES.MAINTENANCE.value}:`);
      }
    }
    console.log('useProvideAuth.redirectToC1Consent');

    const { c1BaseUrl, c1ClientId } = getC1Config();
    getCCID();
    const oauthState = setOauthState();
    setRedirectRoute(location.pathname);

    const oauthSearchParams = new URLSearchParams({
      ...(assertion != null ? { login_token_hint: assertion } : {}),
      client_id: c1ClientId,
      grant_type: 'authorization_code',
      redirect_uri: encodeURI(window.location.origin),
      scope,
      state: oauthState,
      response_type: 'code',
    }).toString();

    window.location = `${c1BaseUrl}/oauth2/authorize?${oauthSearchParams}`;
  };

  const signout = (isDummyUser = false) => {
    console.log('useProvideAuth.signout');
    clearReservationPageBackRoute();
    dispatch(actionSignoutUserRequest({ isDummyUser }));
    clearExpirationTimestamp();
  };

  return {
    user,
    signinWithJwt,
    signinWithAuthCode,
    signout,
    searchParams: authSearchParams,
    redirectToC1Consent,
    redirectToC1Challenge,
  };
}
