import React from 'react';
import { Box } from '@material-ui/core';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from '../../store';

const PageLayout = ({ children }) => {
  return (
    <Box>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Box>
  );
};

export default PageLayout;
