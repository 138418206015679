import React from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchRedirect } from '../../../components/search/helpers';
import { actionSetCurrentFilters } from '../../../store/Filter/FilterAction';
import { FILTER_TYPES } from '../../../utils/constants/FilterTypes';
import {
  Container,
  makeStyles,
  useMediaQuery,
  useTheme,
  withStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import SectionTitle from '../../../components/spoon/SectionTitle';
import { ReactComponent as BenefitsIcon } from '../../../assets/icons/grv-benefits.svg';
import Typography from '../../../components/overrides/Typography';
import { Button, CtaLink } from '../../../components/spoon/Button';
import {
  BOOK_A_TABLE_TEXT,
  SIGNATURE_COLLECTION_DESCRIPTION,
  SIGNATURE_COLLECTION_EXPLORE_CTA,
  SIGNATURE_COLLECTION_FEATURED_TAG,
  SIGNATURE_COLLECTION_TEXT,
  VIEW_RESTAURANT_DETAILS_TEXT,
} from '../../../assets/copy';
import { useShortVenueDetails } from '../../../utils/useShortVenueDetails';
import PageSection from '../../../components/core/PageSection';
import {
  trackClickWithDescription,
  trackPageImpression,
} from '../../../utils/useOneTag';
import useCustomIntersectionObserver from '../../../utils/useCustomIntersectionObserver';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    backgroundColor: theme.palette.spoon.blue[70],
    padding: theme.spacing(3, 0, 4),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4, 0, 5),
    },
    [theme.breakpoints.up('xxl')]: {
      padding: theme.spacing(5, 0, 7.5),
    },
  },
  whiteText: {
    color: theme.palette.spoon.white[100],
  },
  img: {
    width: '100%',
    minWidth: 0,
    height: '263px',
    objectFit: 'cover',
    [theme.breakpoints.up('md')]: {
      height: '318px',
      width: '50%',
    },
    [theme.breakpoints.up('xxl')]: {
      height: '326px',
      width: '60%',
    },
  },
  headingWrapper: {
    display: 'flex',
    columnGap: theme.spacing(1.5),
    rowGap: theme.spacing(1),
    flexWrap: 'wrap',
    alignItems: 'baseline',
    [theme.breakpoints.up('xxl')]: {
      flexDirection: 'column',
      rowGap: theme.spacing(2),
    },
  },
  heading: {
    fontWeight: 300,
    [theme.breakpoints.up('xxl')]: {
      fontSize: '32px',
    },
  },
  subheading: {
    fontWeight: 400,
    opacity: 0.8,
    [theme.breakpoints.up('xxl')]: {
      fontSize: '16px',
    },
  },
  featuredTag: {
    textTransform: 'uppercase',
    letterSpacing: '0.5px',
    backgroundColor: theme.palette.spoon.blue[50],
    padding: theme.spacing(0.25, 1),
    borderRadius: '4px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('xxl')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  featuredTagWrapper: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: theme.spacing(3),
    rowGap: theme.spacing(1),
    alignItems: 'center',
    '& > *': {
      color: theme.palette.spoon.white[100],
      [theme.breakpoints.up('xxl')]: {
        fontSize: '14px',
        lineHeight: '20px',
        marginBottom: theme.spacing(1),
      },
    },
  },
  featuredRestaurantContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(1),
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: theme.spacing(4),
      minWidth: '320px',
      width: '50%',
    },
    [theme.breakpoints.up('xxl')]: {
      flexShrink: 0,
      width: '470px',
      justifyContent: 'flex-start',
      marginLeft: 0,
    },
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      gap: theme.spacing(4.5),
    },
    [theme.breakpoints.up('xxl')]: {
      gap: theme.spacing(7.5),
    },
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    [theme.breakpoints.up('xxl')]: {
      marginTop: theme.spacing(2),
      flexDirection: 'row',
      gap: theme.spacing(3),
    },
  },
  accoladeWrapper: {
    display: 'flex',
    columnGap: theme.spacing(1),
    alignItems: 'center',
  },
  accoladeText: {
    lineHeight: '18px',
    color: theme.palette.spoon.white[100],
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
  },
  accoladeImg: {
    width: 12,
    height: 12,
    filter: 'invert(1) grayscale(1) brightness(100) saturate(100)',
  },
  venueDescriptionText: {
    color: theme.palette.spoon.white[100],
    overflow: 'hidden',
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
  },
}));

const SignatureCollectionDescription = withStyles((theme) => ({
  BenefitsIcon: {
    width: 16,
    height: 16,
    '& path': {
      fill: `${theme.palette.yellow[40]} !important`,
    },
    [theme.breakpoints.up('xxl')]: {
      width: 24,
      height: 24,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.75),
    padding: theme.spacing(1, 3),
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 5),
    },
    [theme.breakpoints.up('xxl')]: {
      padding: theme.spacing(1, 0),
    },
  },
  description: {
    color: theme.palette.spoon.white[100],
    [theme.breakpoints.up('md')]: {
      fontSize: '20px',
      lineHeight: '28px',
    },
    [theme.breakpoints.up('xxl')]: {
      fontSize: '24px',
      lineHeight: '32px',
    },
    paddingBottom: theme.spacing(2),
  },
  sectionTitleWrapper: {
    paddingBottom: theme.spacing(1.5),
    [theme.breakpoints.up('xxl')]: {
      paddingBottom: theme.spacing(1),
    },
  },
}))(({ classes, isDesktop, searchSignatureCollection }) => (
  <PageSection className={classes.container}>
    <div className={classes.sectionTitleWrapper}>
      <SectionTitle
        text={SIGNATURE_COLLECTION_TEXT}
        icon={<BenefitsIcon className={classes.benefitsIcon} />}
        color="light"
        size={isDesktop ? 'large' : 'normal'}
      />
    </div>
    <Typography
      variant="small2Normal"
      className={classes.description}
      component="h2">
      {SIGNATURE_COLLECTION_DESCRIPTION}
    </Typography>
    <CtaLink variant="light" size="medium" onClick={searchSignatureCollection}>
      {SIGNATURE_COLLECTION_EXPLORE_CTA}
    </CtaLink>
  </PageSection>
));

export const SignatureCollection = ({ venue, openBookingModal }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xxl'));
  const history = useHistory();
  const redirectTo = useSearchRedirect();
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state);

  const searchSignatureCollection = () => {
    trackClickWithDescription(
      'signature-collection-explore-cta',
      'Link',
      venue.name
    );
    dispatch(
      actionSetCurrentFilters({
        [FILTER_TYPES.CATEGORIES]: categories?.list.filter(
          (category) => category.name === SIGNATURE_COLLECTION_TEXT
        ),
      })
    );
    history.push('/search', { redirectTo });
  };
  const handleOpenBookingModal = () => {
    trackClickWithDescription(
      'signature-collection-open-booking-modal',
      'Button',
      venue.name
    );
    openBookingModal(venue);
  };
  const viewRestaurantDetails = () => {
    trackClickWithDescription(
      'signature-collection-view-restaurant-details',
      'Link',
      venue.name
    );
    history.push(`/venue/${venue.id}`);
  };
  const { getSignatureVenueDetails } = useShortVenueDetails();
  const subheading = getSignatureVenueDetails(venue);
  const accolade = venue.awards?.items?.[0];

  const elementRef = useCustomIntersectionObserver(
    (entry) => {
      trackPageImpression(
        'signature-collection',
        'signature-collection',
        venue?.name
      );
    },
    { threshold: 0.8, triggerOnce: false }
  );

  return (
    <div className={classes.wrapper} ref={elementRef} q>
      <SignatureCollectionDescription
        isDesktop={isDesktop}
        searchSignatureCollection={searchSignatureCollection}
      />
      <div className={classes.contentContainer}>
        <img className={classes.img} src={venue.imagesFull[0]} alt="" />
        <Container className={classes.featuredRestaurantContainer}>
          <div className={classes.featuredTagWrapper}>
            <Typography
              variant="small1Semibold"
              className={classes.featuredTag}>
              {SIGNATURE_COLLECTION_FEATURED_TAG}
            </Typography>
            {accolade && (
              <div className={classes.accoladeWrapper}>
                <img
                  className={classes.accoladeImg}
                  src={accolade.award.icon}
                  alt=""
                />
                <Typography
                  variant="small1Semibold"
                  className={classes.accoladeText}>
                  {accolade.award.secondaryText} - {accolade.award.primaryText}
                </Typography>
              </div>
            )}
          </div>
          <div className={classes.headingWrapper}>
            <Typography
              variant="medium3Light"
              className={clsx(classes.whiteText, classes.heading)}
              component="h3">
              {venue.name}
            </Typography>
            <Typography
              variant={isDesktop ? 'medium1Normal' : 'tiny1Normal'}
              className={clsx(classes.whiteText, classes.subheading)}>
              {subheading}
            </Typography>
          </div>
          <Typography
            variant="small2Normal"
            className={classes.venueDescriptionText}>
            {venue.descriptionShort
              ? venue.descriptionShort
              : venue.description}
          </Typography>
          <div className={classes.buttonWrapper}>
            <Button
              variant="light"
              size={isDesktop ? 'medium' : 'normal'}
              onClick={handleOpenBookingModal}>
              {BOOK_A_TABLE_TEXT}
            </Button>
            <CtaLink
              variant="light"
              size={isDesktop ? 'medium' : 'normal'}
              onClick={viewRestaurantDetails}>
              {VIEW_RESTAURANT_DETAILS_TEXT}
            </CtaLink>
          </div>
        </Container>
      </div>
    </div>
  );
};

SignatureCollection.propTypes = {
  venue: PropTypes.shape({
    imagesFull: PropTypes.arrayOf(PropTypes.string).isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    descriptionShort: PropTypes.string,
    awards: PropTypes.object,

    // used in availability call
    sevenRoomsVenueId: PropTypes.string.isRequired,

    // used in subheading
    neighborhoodId: PropTypes.string,
    priceRange: PropTypes.string,
    cuisineNames: PropTypes.string,
    eventDates: PropTypes.arrayOf(PropTypes.object),
    venueType: PropTypes.string,
  }),
  openBookingModal: PropTypes.func.isRequired,
};

export default SignatureCollection;
