import { useAuth } from '../../utils/useAuth';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getDefaultPaymentAccount } from '../../store/helpers';
import { PAYMENT_METHOD_COMPONENT_TYPES } from './paymentComponentTypes';

export const useGetPaymentMethod = (type) => {
  const { user } = useAuth();
  const { paymentAccount } = useSelector((state) => state.reservations);

  const getDefaultAccount = useCallback(
    () => getDefaultPaymentAccount(user),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, user?.associatedAccounts, user?.c1DefaultPaymentMethodAccountId]
  );

  if (type === PAYMENT_METHOD_COMPONENT_TYPES.PROFILE) {
    const defaultAccount = getDefaultAccount();
    return defaultAccount;
  }

  return paymentAccount;
};
