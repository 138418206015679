import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SEARCH_FILTER_ACCOLADE_LABEL,
  SEARCH_FILTER_ATMOSPHERE_LABEL,
  SEARCH_FILTER_CATEGORY_LABEL,
  SEARCH_FILTER_CUISINE_LABEL,
  SEARCH_FILTER_NEIGHBORHOOD_LABEL,
  SEARCH_FILTER_OCCASION_LABEL,
  SEARCH_FILTER_PRICE_RANGE_LABEL,
  SEARCH_FILTER_RESERVATION_TYPE_LABEL,
} from '../../../assets/copy';
import {
  actionResetCurrentFilters,
  actionSetCurrentFilters,
} from '../../../store/Filter/FilterAction';
import { PRICE_RANGE_LIST } from '../../../utils/constants/PriceRangeList';
import {
  FILTER_TYPES,
  VENUE_TYPE_FILTERS,
  VENUE_LABELS_TO_OCCASIONS_MAP,
} from '../../../utils/constants/FilterTypes';
import { getFiltersCount } from '../../../utils/searchFilters';
import FilterModalContainer from './FilterModalContainer';
import FilterSection from './FilterSection';
import { useNeighborhoodsInMarket } from './helpers/useNeighborhoodsInMarket';

// list needs to be mutable
const priceRangeList = PRICE_RANGE_LIST.map((priceRange) => ({
  ...priceRange,
}));

const venueTypeFilters = [...VENUE_TYPE_FILTERS];

const Filter = () => {
  const dispatch = useDispatch();
  const { atmospheres, categories, cuisines, accolades } = useSelector(
    (state) => state
  );
  const { currentFilters } = useSelector((state) => state.filters);
  const neighborhoodsInMarket = useNeighborhoodsInMarket();
  const filtersCount = useMemo(
    () => getFiltersCount(currentFilters),
    [currentFilters]
  );

  const [selectFilters, setSelectFilters] = useState(currentFilters);

  const handleApplyFilters = useCallback(() => {
    dispatch(actionSetCurrentFilters(selectFilters));
  }, [dispatch, selectFilters]);

  const handleFilterSelect = (item, type) => {
    const filterWithoutItem = selectFilters[type].filter(
      (i) => i.id !== item.id
    );
    if (filterWithoutItem.length === selectFilters[type].length) {
      setSelectFilters({
        ...selectFilters,
        [type]: [...selectFilters[type], { ...item, timestamp: new Date() }],
      });
    } else {
      setSelectFilters({
        ...selectFilters,
        [type]: filterWithoutItem,
      });
    }
  };

  const handleResetFilters = useCallback(() => {
    dispatch(actionResetCurrentFilters());
  }, [dispatch]);

  // When currentFilters updates, set selectFilters to be currentFilters
  useEffect(() => {
    setSelectFilters(currentFilters);
  }, [currentFilters, setSelectFilters]);

  return (
    <FilterModalContainer
      filtersCount={filtersCount}
      onApply={handleApplyFilters}
      onReset={handleResetFilters}>
      <FilterSection
        title={SEARCH_FILTER_RESERVATION_TYPE_LABEL}
        defaultExpanded={true}
        list={venueTypeFilters}
        activeList={selectFilters.venueType}
        id={FILTER_TYPES.VENUE_TYPE}
        onChange={handleFilterSelect}
      />
      <FilterSection
        title={SEARCH_FILTER_PRICE_RANGE_LABEL}
        defaultExpanded={true}
        list={priceRangeList}
        activeList={selectFilters.priceRange}
        id={FILTER_TYPES.PRICE_RANGE}
        onChange={handleFilterSelect}
      />
      <FilterSection
        title={SEARCH_FILTER_CATEGORY_LABEL}
        list={categories.list}
        activeList={selectFilters.categories}
        id={FILTER_TYPES.CATEGORIES}
        onChange={handleFilterSelect}
      />
      <FilterSection
        title={SEARCH_FILTER_ACCOLADE_LABEL}
        list={accolades.list}
        activeList={selectFilters.accolades}
        id={FILTER_TYPES.ACCOLADES}
        onChange={handleFilterSelect}
      />
      <FilterSection
        title={SEARCH_FILTER_CUISINE_LABEL}
        list={cuisines.list}
        activeList={selectFilters.cuisines}
        id={FILTER_TYPES.CUISINES}
        onChange={handleFilterSelect}
      />
      <FilterSection
        title={SEARCH_FILTER_ATMOSPHERE_LABEL}
        list={atmospheres.list}
        activeList={selectFilters.atmospheres}
        id={FILTER_TYPES.ATMOSPHERES}
        onChange={handleFilterSelect}
      />
      <FilterSection
        title={SEARCH_FILTER_NEIGHBORHOOD_LABEL}
        list={neighborhoodsInMarket}
        activeList={selectFilters.neighborhoods}
        id={FILTER_TYPES.NEIGHBORHOODS}
        onChange={handleFilterSelect}
      />
      <FilterSection
        title={SEARCH_FILTER_OCCASION_LABEL}
        list={Array.from(VENUE_LABELS_TO_OCCASIONS_MAP).map(
          ([label, occasion]) => ({
            id: label,
            name: occasion,
          })
        )}
        activeList={selectFilters.occasions}
        id={FILTER_TYPES.OCCASIONS}
        onChange={handleFilterSelect}
      />
    </FilterModalContainer>
  );
};

export default Filter;
