import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { WIDTH_BREAKPOINT } from '../../../utils/constants/Breakpoints';
import { ERROR_PAGE_TYPES } from '../../../utils/constants/ErrorPageTypes';
import { useViewport } from '../../../utils/useViewport';
import { useAuth } from '../../../utils/useAuth';
import { NavBar } from '../../spoon/NavBar';

const ROUTES_TO_HIDE = new Set(['/onboarding', '/users']);

const AppBar = () => {
  const { width } = useViewport();
  const { errorType } = useSelector((state) => state.error);
  const location = useLocation();
  const { user } = useAuth();
  const { market } = useSelector((state) => state.markets);

  const shouldShow = () => {
    const isAuthenticated = user?.session?.isAuthenticated;
    const isOnboarded = user?.marketId != null;
    const isMarketSelected = market?.id != null;
    const shouldHideOnRoute =
      ROUTES_TO_HIDE.has(location.pathname) ||
      location.pathname.startsWith('/permalink');

    if (
      !isAuthenticated ||
      !isOnboarded ||
      !isMarketSelected ||
      shouldHideOnRoute
    ) {
      return false;
    }

    if (location.pathname !== '/error') {
      return true;
    }

    const error = ERROR_PAGE_TYPES[errorType] || ERROR_PAGE_TYPES.GENERIC;
    return error.shouldShowAppBar === true;
  };

  return (
    shouldShow() && (
      <NavBar variant={width < WIDTH_BREAKPOINT ? 'compact' : 'default'} />
    )
  );
};

export default AppBar;
