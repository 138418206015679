import * as moment from 'moment';
import { DateTime } from 'luxon';

import {
  SHORT_DATE,
  SHORT_DATE_WITH_WEEKDAY,
  MEDIUM_DATE,
  LONG_DATE_WITH_WEEKDAY,
  SPECIAL_EVENT_DATE_SELECT_FORMAT,
  YEAR_MONTH_DATE_FORMAT,
  YEAR_MONTH_FORMAT,
  DATE_FORMAT,
  FULL_MONTH_NAME_AND_DATE_FORMAT,
  FULL_MONTH_NAME_FORMAT,
  FULL_DAY_FORMAT,
  DAY_AND_DATE_FORMAT
} from '../assets/dateFormats';

import { HOUR_PERIOD_FORMAT } from '../assets/timeFormats';

/**
 * Returns date string in medium date format (MMMM DD). For example, Feb 01.
 * @param {*} specialDate - string in date format MM/DD
 * @returns Date string formatted as MMMM DD
 */
const formatMediumSpecialDate = (specialDate) => {
  if (!specialDate) return;
  // Set year as 2020 to account for leap year special date on 2/29
  return moment(`${specialDate}/20`, 'MM/DD/YY', true).format(MEDIUM_DATE);
};

const isValidShortSpecialDate = (specialDate) => {
  if (!specialDate) return true;

  // Set year as 2020 to account for leap year special date on 2/29
  const momentValue = moment(`${specialDate}/20`, 'MM/DD/YY', true);
  return momentValue.isValid();
};

const isTimeBetweenInclusive = (
  testTimeString,
  startTimeString,
  endTimeString
) => {
  const testTimeMoment = moment(testTimeString, [HOUR_PERIOD_FORMAT]);
  const startTimeMoment = moment(startTimeString, [HOUR_PERIOD_FORMAT]);
  const endTimeMoment = moment(endTimeString, [HOUR_PERIOD_FORMAT]);

  return testTimeMoment.isBetween(
    startTimeMoment,
    endTimeMoment,
    'minutes',
    '[]'
  );
};

const isDateInBetween = (currentDate, dateInRange) => {
  const HALO = 8;
  const haloStartDate = moment(currentDate).subtract(HALO, 'days');
  const haloEndDate = moment(currentDate).add(HALO, 'days');
  return dateInRange.isBetween(haloStartDate, haloEndDate);
};

const getFormattedDate = (date, dateFormat) => {
  return date ? moment(date).format(dateFormat) : '';
};

const getFormattedShortDate = (date) => getFormattedDate(date, SHORT_DATE);

const getFormattedShortDateWithWeekday = (date) =>
  getFormattedDate(date, SHORT_DATE_WITH_WEEKDAY);

const getFormattedLongDateWithWeekday = (date) =>
  getFormattedDate(date, LONG_DATE_WITH_WEEKDAY);

/**
 * Move selected reservation time slot (with 15 or 45 min marks) 15 minutes back of the same
 * date and hour.
 * i.e: 6:15 AM -> 6:00 AM, 7:45 PM -> 7:30 PM
 **/
const sanitizeSelTime = (selTime) => {
  return selTime.replace('15', '00').replace('45', '30');
};

const addDays = (date, daysToAdd) => {
  const result = new Date(date);
  result.setDate(result.getDate() + daysToAdd);
  return result;
};

const checkIfHasTimeslots = (timeslotsArray) => {
  if (!timeslotsArray) return false;

  for (const item of timeslotsArray) {
    if (item.timeslots?.length > 0) return true;
  }
  return false;
};

const dateToISOString = (date) => {
  return moment(date).toISOString();
};

/**
 * Given a date/time string (format `YYYY-MM-DD HH:mm:ss`), and IANA time zone
 * string (example: `America/New_York`), returns an ISO date string.
 *
 * @param {*} dateTimeString - date time string formatted as`YYYY-MM-DD HH:mm:ss`
 * @param {*} timezone - IANA time zone string
 */
const generateISOString = (dateTimeString, timezone) => {
  const dateTime = DateTime.fromFormat(dateTimeString, 'yyyy-LL-dd HH:mm:ss', {
    zone: timezone,
  });
  if (!dateTime.isValid) {
    throw new Error('Could not parse date time');
  }
  return dateTime.toISO();
};

const formatDate = (
  dateString,
  locale = 'en-US',
  options = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  }
) => {
  const date = new Date(dateString);
  return date.toLocaleDateString(locale, options);
};

const toISODate = (date) => DateTime.fromISO(date).toISODate();

const isOnOrBeforeDate = (d1, d2) => {
  const beforeDate = DateTime.fromISO(d1);
  const targetDate = DateTime.fromISO(d2);
  return beforeDate.startOf('day') <= targetDate.startOf('day');
};

const formatSpecialEventSelectDate = (date) =>
  DateTime.fromISO(date).toFormat(SPECIAL_EVENT_DATE_SELECT_FORMAT);

const createDateMonthForCardText = (eventDates) => {
  const obj = {};
  eventDates?.forEach((eventDate) => {
      const month = getYearMonth(eventDate);
      if (!obj[month]) {
        obj[month] = new Set([getDate(eventDate)]); 
      } else {
        obj[month].add(getDate(eventDate));
      }
  });
  return obj;
}

const getYearMonth = (date) => DateTime.fromFormat(date, YEAR_MONTH_DATE_FORMAT).toFormat(YEAR_MONTH_FORMAT);

const getDate = (date) => DateTime.fromFormat(date, YEAR_MONTH_DATE_FORMAT).toFormat(DATE_FORMAT);

const getFullMonthAndDate = (date) => DateTime.fromFormat(date, YEAR_MONTH_DATE_FORMAT).toFormat(FULL_MONTH_NAME_AND_DATE_FORMAT);

const getFullMonth = (date) => DateTime.fromFormat(date, YEAR_MONTH_FORMAT).toFormat(FULL_MONTH_NAME_FORMAT);

const getFullDay = (date) => DateTime.fromFormat(date, YEAR_MONTH_DATE_FORMAT).toFormat(FULL_DAY_FORMAT);

const getDayDate = (date) => DateTime.fromFormat(date, YEAR_MONTH_DATE_FORMAT).toFormat(DAY_AND_DATE_FORMAT);

export {
  formatMediumSpecialDate,
  isValidShortSpecialDate,
  isTimeBetweenInclusive,
  isDateInBetween,
  getFormattedDate,
  getFormattedShortDate,
  getFormattedShortDateWithWeekday,
  getFormattedLongDateWithWeekday,
  sanitizeSelTime,
  addDays,
  checkIfHasTimeslots,
  dateToISOString,
  generateISOString,
  formatDate,
  toISODate,
  isOnOrBeforeDate,
  formatSpecialEventSelectDate,
  createDateMonthForCardText,
  getFullMonthAndDate,
  getFullMonth,
  getFullDay,
  getDayDate
};
