import { useSelector } from 'react-redux';
import { getDayDate } from './dateTime';
import {
  COMMA_JOIN,
  MULTIPLE_DATE_TEXT,
  VENUE_DETAILS_JOIN,
} from '../assets/copy';

export const useShortVenueDetails = () => {
  const { list: neighborhoods } = useSelector((state) => state.neighborhoods);

  const getShortVenueDetails = (venue) => {
    const neighborhood = neighborhoods?.find(
      (neighborhood) => neighborhood.id === venue.neighborhoodId
    )?.name;
    switch (venue.venueType) {
      case 'EVENT':
        const eventDate =
          venue.eventDates?.length === 1
            ? getDayDate(venue.eventDates[0])
            : MULTIPLE_DATE_TEXT;
        const location = [venue.city, venue.state].filter(Boolean).join(COMMA_JOIN);
        return [eventDate, location].filter(Boolean).join(VENUE_DETAILS_JOIN);
      case 'REGULAR':
      default:
        const cuisine = venue?.cuisines?.items[0]?.cuisine?.name;
        const priceRange = venue.priceRange;
        return [cuisine, priceRange, neighborhood]
          .filter(Boolean)
          .join(VENUE_DETAILS_JOIN);
    }
  };

  const getSignatureVenueDetails = (venue) => {
    const neighborhood = neighborhoods?.find(
      (neighborhood) => neighborhood.id === venue.neighborhoodId
    )?.name;
    const cuisine = venue?.cuisines?.items[0]?.cuisine?.name;
    return [cuisine, neighborhood].filter(Boolean).join(VENUE_DETAILS_JOIN);
  };

  return { getShortVenueDetails, getSignatureVenueDetails };
};
