import React, { useEffect } from 'react';
import { List, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OnboardingWelcomeBg from '../../assets/images/seville-day3-179.jpeg';
import WelcomeItem from '../../components/onboarding/WelcomeItem';
import BulletOneSvg from '../../assets/icons/dining-blue.svg';
import BulletTwoSvg from '../../assets/icons/tickets-blue.svg';
import BulletThreeSvg from '../../assets/icons/ribbon-blue.svg';
import Typography from '../../components/overrides/Typography';
import {
  ONBOARDING_LITE_WELCOME_TEXT,
  ONBOARDING_LITE_BULLET_ONE_TEXT,
  ONBOARDING_LITE_BULLET_TWO_TEXT,
  ONBOARDING_LITE_BULLET_THREE_TEXT,
  NEXT_BUTTON_TEXT,
} from '../../assets/copy';
import { Layout, MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from './Layout';
import {
  trackClickWithDescription,
  trackPageView,
} from '../../utils/useOneTag';
import ProgressButton from './ProgressButton';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  welcomeItemText: {
    color: theme.palette.beacon.midnight,
    fontSize: '14px',
    lineHeight: '20px',
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  welcomeItemIcon: {
    '& path': {
      fill: theme.palette.beacon.midnight,
    },
  },
  title: {
    color: theme.palette.beacon.midnight,
    lineHeight: '40px',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      marginTop: theme.spacing(2),
    },
  },
  scrollable: {
    overflowY: 'auto',
  },
  divider: {
    '&.MuiDivider-root': {
      backgroundColor: theme.palette.beacon.heritageRed,
      [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
        backgroundColor: theme.palette.beacon.brick,
      },
    },
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(MOBILE_BREAKPOINT)]: {
      marginBottom: theme.spacing(5),
      marginRight: theme.spacing(14),
    },
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      marginRight: theme.spacing(8),
    },
  },
  loadingText: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      marginBottom: theme.spacing(5),
      fontSize: '20px',
      lineHeight: '30px',
    },
  },
  circle: {
    color: theme.palette.beacon.brick,
    marginBottom: theme.spacing(2),
  },
  button: {
    backgroundColor: theme.palette.beacon.atlantic,
    width: '180px',
    height: 'min-content',
    padding: theme.spacing(1, 3.5, 1.25),
    borderRadius: 6,
    '& .MuiButton-label p': {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  listItem: {
    [theme.breakpoints.down(MOBILE_BREAKPOINT)]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

const Welcome = ({ handleNext }) => {
  const classes = useStyles();
  const contents = [
    {
      icon: BulletOneSvg,
      content: ONBOARDING_LITE_BULLET_ONE_TEXT,
    },
    {
      icon: BulletTwoSvg,
      content: ONBOARDING_LITE_BULLET_TWO_TEXT,
    },
    {
      icon: BulletThreeSvg,
      content: ONBOARDING_LITE_BULLET_THREE_TEXT,
    },
  ];

  const onSubmit = () => {
    trackClickWithDescription('onboarding-welcome-next-cta', 'Button');
    handleNext();
  };

  useEffect(() => {
    trackPageView('/onboarding__welcome');
  }, []);

  return (
    <Layout backgroundImage={OnboardingWelcomeBg}>
      <Box className={classes.wrapper}>
        <Typography
          className={classes.title}
          component="h1"
          variant="large1LightFlex">
          {ONBOARDING_LITE_WELCOME_TEXT}
        </Typography>
        <Box className={classes.scrollable}>
          <List className={classes.list}>
            {contents.map((item, index) => (
              <WelcomeItem
                item={item}
                key={index}
                classesOverride={{
                  content: classes.welcomeItemText,
                  avatar: classes.welcomeItemIcon,
                  root: classes.listItem,
                }}
              />
            ))}
          </List>
          <Divider className={classes.divider} />
          <ProgressButton
            variant="small2Semibold"
            onClick={onSubmit}
            text={NEXT_BUTTON_TEXT}
            classesOverride={{ button: classes.button }}
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default Welcome;
