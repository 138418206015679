import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Card, CardContent, CardMedia } from '@material-ui/core';
import SignatureStarSvg from '../../assets/icons/grv-benefits.svg';
import Typography from '../overrides/Typography';
import { EXCLUSIVE_ALERT_TITLE_ALT } from '../../assets/copy';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.secondary[60]}`,
    padding: theme.spacing(2, 3),
    width: 'auto',
    '&:first-of-type': {
      paddingTop: 0,
    },
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(0, 3, 2),
    },
    [theme.breakpoints.up('xxl')]: {
      padding: theme.spacing(0, 0, 2),
    },
  },
  wrapper: {
    display: 'grid',
    gridColumnGap: theme.spacing(2),
    gridTemplateColumns: 'auto 1fr',
  },
  image: {
    height: 48,
    width: 48,
  },
  square40: {
    height: 40,
    width: 40,
  },
  content: {
    alignSelf: 'center',
    '&:last-child': {
      padding: 0,
    },
  },
  link: {
    display: 'inline',
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const ExclusiveTagging = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root} elevation={0}>
      <Box className={classes.wrapper}>
        <CardMedia
          className={clsx(classes.square40)}
          image={SignatureStarSvg}
          alt=""
        />
        <CardContent className={classes.content}>
          <Typography variant="tiny1Normal">
            {EXCLUSIVE_ALERT_TITLE_ALT}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  );
};

export default ExclusiveTagging;
