import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Modal, Fade, Backdrop } from '@material-ui/core';
import { BREAKPOINT_VALUES } from '../../utils/constants/Breakpoints';
import { useViewport } from '../../utils/useViewport';
import PageSection from '../core/PageSection';
import VenueHeroImage from './VenueHeroImage';
import { VenueImageColumn, VenueImageGrid } from './VenueImageGrid';
import { VenueLightBox, VenueLightBoxButton } from './VenueLightBox';
import theme from '../../../src/theme';
import CloseButton from '../core/CloseButton';
import Typography from '../overrides/Typography';
import clsx from 'clsx';
import { focusVisible } from '../../utils/ada/contrastStyles';
import { VENUE_IMAGE_FROM_TEXT, VIEW_FULL_SIZE_TEXT } from '../../assets/copy';
import SignatureCollectionBadge from '../core/Badge/SignatureCollectionBadge';
import NewRestaurantBadge from '../core/Badge/NewRestaurantBadge';

const useStyles = makeStyles((theme) => ({
  container: ({ imageCount }) => ({
    [theme.breakpoints.up('xl')]: {
      height: 424,
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.up('xxl')]: {
      maxWidth: `calc(${BREAKPOINT_VALUES.xxl}px - (${theme.spacing(5)} * 2))`,
    },
    position: 'relative',
    height: '100%',
  }),
  grid: {
    height: '100%',
    width: '100%',
  },
  button: {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    position: 'absolute',
    ...focusVisible(),
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(10),
    flexWrap: 'nowrap',
    flex: 1,
  },
  toolbarSmallDevice: {
    marginBottom: theme.spacing(1),
    flexDirection: 'row',
  },
  paper: {
    backgroundColor: theme.palette.background.white,
    boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.up('xl')]: {
      borderRadius: 4,
    },
    position: 'static',
    height: '100%',
  },
  venuetitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  venueTitleSmallDevice: {
    padding: theme.spacing(1, 0, 0, 10),
  },
  close: {
    padding: theme.spacing(2, 3, 0, 0),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeSmallDevice: {
    padding: theme.spacing(0.5, 3, 0, 0),
  },
  partnerImageContainer: {
    borderRadius: '50%',
    margin: theme.spacing(-0.25, 0, 0, -0.25),
    backgroundColor: theme.palette.charcoal[60],
    border: `solid 2px ${theme.palette.background.white}`,
    height: 28,
    width: 28,
  },
  partnerImage: {
    height: 20,
    width: 20,
    padding: theme.spacing(0.5),
    filter: 'brightness(0) invert(1)',
  },
  partnerName: {
    margin: theme.spacing(0, 1),
  },
  partnerBadge: {
    border: `solid 2px ${theme.palette.background.white}`,
    height: 30,
    width: 'auto',
    bottom: -20,
    position: 'absolute',
    backgroundColor: theme.palette.background.white,
    borderRadius: 16,
    color: theme.palette.primary.main,
    display: 'flex',
    marginLeft: theme.spacing(2.5),
  },
  badges: {
    position: 'absolute',
    top: 16,
    left: 16,
    zIndex: 1,
  },
  badgeContainer: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const VenueImageHeader = ({ venue }) => {
  const { width } = useViewport();
  const { imagesFull: images, imagesSmall: thumbs, id: venueId, name } = venue;
  const imageCount = images?.length ?? 0;
  const classes = useStyles({ imageCount });
  const isMobile = width < BREAKPOINT_VALUES.xl;
  const [open, setOpen] = useState(false);
  const [indexOnOpen, setIndexOnOpen] = useState(0);
  const inputRef = useRef();
  const isSmallDevice = width >= 450;

  const handleOpen = (event) => {
    const id = event?.target?.id;
    const picIndex = id.substring(id.indexOf('_') + 1);
    setIndexOnOpen(id ? +picIndex : 0);
    setOpen(true);
  };

  function closeModal() {
    setOpen(false);
  }

  const handleFocus = (e) => {
    if (inputRef?.current) {
      inputRef.current.focus();
    }
  };
  const renderVenueName = () => {
    return (
      <Box
        className={clsx(
          classes.venuetitle,
          isSmallDevice && classes.venueTitleSmallDevice
        )}>
        <Typography variant="medium2Semibold">{name}</Typography>
      </Box>
    );
  };

  const renderCloseButton = () => {
    return (
      <Box
        className={clsx(
          classes.close,
          isSmallDevice && classes.closeSmallDevice
        )}>
        <CloseButton
          fill={theme.palette.interactionBlue[50]}
          close={closeModal}
        />
      </Box>
    );
  };
  const renderToolbar = () => {
    return (
      <Box
        className={clsx(
          classes.toolbar,
          isSmallDevice && classes.toolbarSmallDevice
        )}>
        {isSmallDevice ? (
          <>
            {renderVenueName()}
            {renderCloseButton()}
          </>
        ) : (
          <>
            {renderCloseButton()}
            {renderVenueName()}
          </>
        )}
      </Box>
    );
  };

  const renderVenueMobileImages = () => {
    return (
      <Modal
        open={open}
        onClose={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onKeyPress={(e) => e.key === 'Tab' && handleFocus(e)}
        contentLabel={name}>
        <Fade in={open}>
          <Box className={classes.paper} role="dialog">
            {renderToolbar()}
            <VenueHeroImage images={images} showStatus showArrows />
          </Box>
        </Fade>
      </Modal>
    );
  };

  const Badges = () => {
    // There will be more badges but SignatureCollection is the only one going to be added for now. Ideally there would be an array of badges
    const liveDate = new Date(venue?.liveDate).getTime();
    const sixtyDayDuration = 60 * 24 * 60 * 60 * 1000;
    const today = new Date().getTime();
    const displayNew = today - liveDate < sixtyDayDuration;

    return (
      <Box className={classes.badges}>
        {(displayNew || venue?.isSignatureCollection) && (
          <Box className={classes.badgeContainer}>
            {venue?.isSignatureCollection && <SignatureCollectionBadge />}
            {displayNew && <NewRestaurantBadge />}
          </Box>
        )}
      </Box>
    );
  };

  if (!images || imageCount === 0) return null;

  return (
    <>
      <PageSection className={classes.container}>
        <Badges />
        {isMobile ? (
          <VenueHeroImage
            images={images}
            showStatus={false}
            showArrows={false}
          />
        ) : (
          <VenueImageGrid className={classes.grid}>
            {images.slice(0, 3).map((src, index) => (
              <VenueImageColumn
                imageCount={imageCount}
                src={src}
                key={index}
                alt={`${VENUE_IMAGE_FROM_TEXT} ${name}`}
                {...(imageCount > 1 && {
                  role: 'button',
                  alt: `${VENUE_IMAGE_FROM_TEXT} ${name}. ${VIEW_FULL_SIZE_TEXT}`,
                  columnKey: index,
                  id: `${venueId}_${index}`,
                  index,
                  onPress: handleOpen,
                })}
              />
            ))}
          </VenueImageGrid>
        )}
        {venue?.partners?.items[0]?.partner?.icon && (
          <Box className={classes.partnerBadge}>
            <Box className={classes.partnerImageContainer}>
              <img
                className={classes.partnerImage}
                src={venue?.partners?.items[0]?.partner?.icon}
                alt=""
              />
            </Box>
            <Typography
              variant={'small1Semibold'}
              className={classes.partnerName}>
              {venue?.partners?.items[0]?.partner?.name}
            </Typography>
          </Box>
        )}

        {imageCount > 1 && (
          <VenueLightBoxButton
            className={classes.button}
            imageCount={imageCount}
            onOpen={handleOpen}
          />
        )}
      </PageSection>
      {imageCount > 1 && !isMobile ? (
        <VenueLightBox
          images={images}
          thumbs={thumbs}
          onClose={() => setOpen(false)}
          open={open}
          indexOnOpen={indexOnOpen}
          venueName={name}
        />
      ) : (
        renderVenueMobileImages()
      )}
    </>
  );
};

export default VenueImageHeader;
