import React, { useMemo, useState, useRef } from 'react';
import { Box, makeStyles, Popover } from '@material-ui/core';
import Divider from '../../overrides/Divider';
import { useSelector } from 'react-redux';
import ChevronDownSvg from '../../../assets/icons/grv-chevron-down.svg';
import Typography from '../../overrides/Typography';
import NavigationLink from '../NavigationLink';
import { NAVIGATION_DIALOG_SCREENREADER_TEXT } from '../../../assets/copy';
import { DESKTOP_USER_NAVIGATION_LINKS } from '../../../utils/constants/Links';
import ScreenReaderText from '../ScreenReaderText';
import { enterKeyPressHandler } from '../../../utils/keyPresses';
import { useCopy } from '../../../utils/useCopy';
import { FocusScope } from '@react-aria/focus';
import { trackClick } from '../../../utils/useOneTag';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  dropdownTrigger: {
    ...theme.typography.small4Normal,
    background: 'transparent',
    border: 'none',
    color: theme.palette.text,
    cursor: 'pointer',
    padding: 0,
    whiteSpace: 'nowrap',
  },
  dropdownTriggerImage: {
    marginLeft: theme.spacing(1.5),
  },
  dropdownContainer: {
    borderRadius: 4,
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.2)',
    padding: theme.spacing(1, 2, 0.5),
    width: 240,
  },
  dropdownUserInfo: {
    color: theme.palette.charcoal[40],
    padding: theme.spacing(1.5, 0),
  },
  dropdownNavigationLink: {
    display: 'block',
    margin: theme.spacing(1.5, 0),
  },
  bold: {
    fontWeight: 600,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const UserNavigationDropdown = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useSelector((state) => state.user);
  const ref = useRef();
  const { getCopy } = useCopy();
  const welcomeMessage = useMemo(
    () => getCopy('WELCOME_TEXT', { firstName: user?.firstName }),
    [getCopy, user]
  );

  const handleClick = (event) => {
    ref?.current?.focus();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUserNavClick = (event, dataCy) => {
    trackClick(event, dataCy, 'Link');
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {user?.firstName && (
        <button
          aria-haspopup="true"
          ref={ref}
          className={clsx(classes.dropdownTrigger, classes.bold, classes.flex)}
          onClick={handleClick}
          data-heap-redact-text="true"
          id="popoverbutton"
          aria-expanded={open}>
          {welcomeMessage}
          <img
            alt=""
            className={classes.dropdownTriggerImage}
            src={ChevronDownSvg}
          />
        </button>
      )}
      {open && (
        <Popover
          id={'desktop-dropdown-menu'}
          classes={{ paper: classes.dropdownContainer }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onKeyPress={enterKeyPressHandler && handleClose}
          disableScrollLock={true}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <ScreenReaderText tabIndex={-1} id="instructions">
            {NAVIGATION_DIALOG_SCREENREADER_TEXT}
          </ScreenReaderText>
          <FocusScope contain restoreFocus autoFocus>
            <Box className={classes.dropdownUserInfo}>
              {user?.firstName && (
                <button
                  className={classes.dropdownTrigger}
                  data-heap-redact-text="true"
                  id="popoverButton"
                  aria-label={`${welcomeMessage}: `}
                  aria-expanded={open}
                  onClick={handleClose}>
                  {`${user.firstName} ${user.lastName}`}
                </button>
              )}
              <Typography color="charcoal" variant="small4Normal" redact={true}>
                {user.emailAddress}
              </Typography>
            </Box>
            {DESKTOP_USER_NAVIGATION_LINKS.map((link) => (
              <>
                <Divider />
                <NavigationLink
                  label={link.label}
                  onClick={(e) => {
                    handleUserNavClick(e, link.dataCy);
                  }}
                  path={link.path}
                  style={classes.dropdownNavigationLink}
                  variant={link.variant}
                  data-cy={link.dataCy}
                />
              </>
            ))}
          </FocusScope>
        </Popover>
      )}
    </>
  );
};

export default UserNavigationDropdown;
