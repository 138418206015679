import React, { useState } from 'react';
import { ButtonBase, makeStyles } from '@material-ui/core';
import Typography from '../../overrides/Typography';
import PropTypes from 'prop-types';
import { OutlinedButton, TextButton } from '../../spoon/Button';
import { AnimatePresence, motion } from 'framer-motion';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { trackPageImpression } from '../../../utils/useOneTag';
import { HOME_HERO_CAROUSEL_EVERGREEN_TITLE } from '../../../assets/copy';
import { focusVisible } from '../../../utils/ada/contrastStyles';
import { removeHtmlTags } from '../../../utils/stringHelperMethods';
import useCustomIntersectionObserver from '../../../utils/useCustomIntersectionObserver';

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '192px',
    alignItems: 'flex-start',
    [theme.breakpoints.up('xxl')]: {
      minHeight: '256px',
    },
  },
  label: {
    lineHeight: '18px',
    textTransform: 'uppercase',
    color: '#6e6e6e',
    letterSpacing: '.5px',
    marginBottom: '12px',
    minHeight: '18px',
  },
  heading: {
    fontWeight: 300,
    lineHeight: '32px',
    color: '#121212',
    marginBottom: '8px',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    [theme.breakpoints.up('xxl')]: {
      letterSpacing: '-.25px',
      fontSize: 40,
      lineHeight: '48px',
      marginBottom: '16px',
    },
  },
  headingButton: {
    ...focusVisible(),
    textAlign: 'left',
  },
  subheading: {
    color: '#6e6e6e',
    marginBottom: '12px',
    [theme.breakpoints.up('xxl')]: {
      ...theme.typography.medium1Normal,
      marginBottom: '12px',
    },
  },
  description: {
    marginBottom: theme.spacing(2),
    flexGrow: 1,
  },
  descriptionText: {
    color: '#121212',
    fontSize: '14px',
    lineHeight: '21px',
    [theme.breakpoints.up('xxl')]: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    overflow: 'hidden',
    width: '100%',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
  },
  buttonContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  secondaryButton: {
    padding: theme.spacing(1),
  },
}));

export const CarouselContent = ({
  label,
  heading,
  subheading,
  description,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
  learnMoreOnClick,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('xxl'));
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseEnter = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };
  const isEvergreenTile = heading === HOME_HERO_CAROUSEL_EVERGREEN_TITLE;

  const elementRef = useCustomIntersectionObserver(
    (entry) => {
      trackPageImpression('hero', 'hero-carousel', heading);
    },
    { threshold: 0.8, triggerOnce: false }
  );

  return (
    <AnimatePresence exitBeforeEnter>
      <motion.div
        className={classes.contentContainer}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        key={`${label}${heading}${description}`}
        ref={elementRef}>
        <Typography variant="small1Semibold" className={classes.label}>
          {label}
        </Typography>
        {learnMoreOnClick ? (
          <ButtonBase
            disableRipple
            className={classes.headingButton}
            tabIndex="0"
            aria-label={heading}
            onClick={isDesktop ? learnMoreOnClick : null}>
            <Typography
              variant="medium4Normal"
              className={classes.heading}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              style={
                isDesktop && learnMoreOnClick
                  ? {
                      cursor: 'pointer',
                      textDecoration: isHovering ? 'underline' : 'none',
                    }
                  : {}
              }
              component="h3">
              {heading}
            </Typography>
          </ButtonBase>
        ) : (
          <Typography
            variant="medium4Normal"
            className={classes.heading}
            component="h3">
            {heading}
          </Typography>
        )}
        {subheading && (
          <Typography variant="small3Normal" className={classes.subheading}>
            {subheading}
          </Typography>
        )}
        <div className={classes.description}>
          <Typography
            display="inline"
            className={classes.descriptionText}
            style={isEvergreenTile ? { WebkitLineClamp: 3 } : {}}>
            {removeHtmlTags(description.replaceAll(/((<br>)|(<br\/>))/g, ' '))}
          </Typography>
        </div>
        <div className={classes.buttonContainer} id="button-container">
          <OutlinedButton
            onClick={primaryButtonOnClick}
            size={isDesktop ? 'medium' : 'normal'}>
            {primaryButtonText}
          </OutlinedButton>
          {secondaryButtonText && (
            <TextButton
              onClick={secondaryButtonOnClick}
              size={isDesktop ? 'medium' : 'normal'}
              classes={{ root: classes.secondaryButton }}>
              {secondaryButtonText}
            </TextButton>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

CarouselContent.propTypes = {
  label: PropTypes.string,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  description: PropTypes.string.isRequired,
  learnMoreOnClick: PropTypes.func,
  primaryButtonText: PropTypes.string,
  primaryButtonOnClick: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  secondaryButtonOnClick: PropTypes.func,
};
