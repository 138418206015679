import React, { useCallback } from 'react';
import clsx from 'clsx';
import {
  generateScreenReaderText,
  convertNullToEmptyString,
} from '../../../utils/stringHelperMethods';
import { makeStyles } from '@material-ui/core';
import typography from '../../../theme/typography';
import { CUSTOMER_TABLE_TEXT } from '../../../assets/copy';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    textAlign: 'center',
    minHeight: 40,
    minWidth: 77,
    maxHeight: 40,
    maxWidth: 77,
    background: theme.palette.primary.main,
    borderRadius: theme.spacing(0.5),
    justifyContent: 'center',
    cursor: 'pointer',
    flexDirection: 'column',
  },

  exclusive: {
    background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.primary.main} 85%, ${theme.palette.yellow[40]} 0%, ${theme.palette.yellow[40]})`,
  },
  insider: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 17,
    height: 14,
    '& path': {
      fill: `${theme.palette.yellow[40]} !important`,
    },
  },
  item: {
    ...typography.small1Semibold,
    color: theme.palette.text.secondary,
    alignSelf: 'center',
    lineHeight: '12px',
    textTransform: 'uppercase',
    letterSpacing: '0.00938em',
    margin: 0,
    display: 'flex',
    textAlign: 'center',
    fontSize: '12px',
    fontFamily: 'Optimist, sans-serif',
    fontWeight: '600',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  slotName: {
    ...typography.small3Normal,
    width: '90%',
    overflow: 'hidden',
    fontSize: '10px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontFamily: ['Optimist', 'sans-serif'].join(','),
    fontWeight: 400,
    color: theme.palette.text.secondary,
    alignSelf: 'center',
    textTransform: 'uppercase',
    lineHeight: '18px',
    fontVariant: 'small3Normal',
    letterSpacing: '0.00938em',
    margin: 0,
    display: 'inline-block',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const TimeButton = ({
  venueParent,
  text,
  timeslot,
  style,
  onClick,
  date,
  type,
  isMobile,
}) => {
  const classes = useStyles({ isMobile });
  const time = convertNullToEmptyString(text);
  const timeSlotName = convertNullToEmptyString(timeslot?.slotName);
  const isExclusive = timeslot?.isExclusive;

  const tableType = isExclusive ? CUSTOMER_TABLE_TEXT : '';

  const handleTimeSlotClick = (event) => {
    onClick(date, time, timeslot);
    window.oneTag.track('link', {
      event_name: 'ui_interaction',
      ui_interaction_action: 'click',
      ui_interaction_element_id: event.currentTarget.id,
      ui_interaction_element_name: 'time-button',
      ui_interaction_element_type: 'Button',
      ui_interaction_element_description: `${time}`,
    });
  };

  const generateScreenReaderLabel = useCallback(() => {
    return generateScreenReaderText(',', tableType, time, timeSlotName);
  }, [tableType, time, timeSlotName]);

  const timeButtonClass = isExclusive
    ? clsx(classes.exclusive, classes.root)
    : classes.root;

  return (
    <div
      className={clsx(timeButtonClass, !!style && style, type && classes[type])}
      onClick={handleTimeSlotClick}
      onKeyPress={(e) => e.key === 'Enter' && onClick(date, time, timeslot)}
      tabIndex={0}
      aria-label={generateScreenReaderLabel()}
      role="link">
      {/* Intentionally use regular p element because Typography causes performance issue / extreme slowness */}

      <p className={classes.item} aria-hidden="true">
        {text}
      </p>
      <p className={classes.slotName} aria-hidden="true">
        {timeSlotName}
      </p>
    </div>
  );
};

TimeButton.propTypes = {
  isInsider: PropTypes.bool,
  venueParent: PropTypes.object,
  text: PropTypes.string,
  timeslot: PropTypes.object,
  style: PropTypes.string,
  onClick: PropTypes.func,
  date: PropTypes.string,
  type: PropTypes.string,
  isMobile: PropTypes.bool,
};

export default TimeButton;
