import React, { useEffect, useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { RESERVATION_PAYMENT_EDIT_TEXT } from '../../../assets/copy';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '../Button';
import { actionSetPaymentAccount } from '../../../store/Reservations/ReservationsAction';
import Divider from '../../overrides/Divider';
import CreditCard from '../../../pages/profile/CreditCard';
import { trackClickWithDescription } from '../../../utils/useOneTag';
import { useGetPaymentMethod } from '../../../pages/profile/useGetPaymentMethod';
import { PAYMENT_METHOD_COMPONENT_TYPES } from '../../../pages/profile/paymentComponentTypes';

const useStyles = makeStyles((theme) => ({
  ctaButton: {
    fontSize: 16,
    lineHeight: '24px',
    padding: theme.spacing(1.5),
    width: '100%',
  },
  paymentSelectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  paymentContainer: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    width: '100%',
  },
  paymentDivider: {
    width: '100%',
    margin: theme.spacing(2, 0, 2),
  },
}));

export const EditPaymentMethod = ({ venue, onSave }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [selectedDefault, setSelectedDefault] = useState(null);
  const currentPaymentAccount = useGetPaymentMethod(
    PAYMENT_METHOD_COMPONENT_TYPES.EDIT_VIEW
  );

  const handleSelectPaymentMethod = (value) => {
    trackClickWithDescription(
      'bottom-sheet-edit-payment-method-modification',
      'Button',
      `venueName: ${venue?.name}, paymentMethod: ${value?.accountDetails.product}`
    );
    setSelectedPaymentMethod(value);
    if (
      value.associatedAccountReferenceId !==
      selectedPaymentMethod.associatedAccountReferenceId
    )
      setSelectedDefault(null);
  };

  const handleSelectDefault = (value) => {
    if (value === selectedDefault) {
      setSelectedDefault(null);
      return;
    }
    setSelectedDefault(value);
  };

  const handleSave = () => {
    trackClickWithDescription(
      'bottom-sheet-edit-payment-method-done',
      'Button',
      venue?.name
    );
    // Cloning selectedPayment to prevent Redux bug where it also saves isDefaultPaymentEdited to user state
    const selectedPaymentMethodClone = {
      ...selectedPaymentMethod,
      isDefaultPaymentEdited: !!selectedDefault,
    };
    dispatch(actionSetPaymentAccount(selectedPaymentMethodClone));
    onSave();
  };

  useEffect(() => {
    if (currentPaymentAccount) {
      setSelectedPaymentMethod(currentPaymentAccount);
    }
    setSelectedDefault(
      currentPaymentAccount?.isDefaultPaymentEdited
        ? currentPaymentAccount.associatedAccountReferenceId
        : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.paymentSelectionContainer} role="radiogroup">
      {user.associatedAccounts.map((account) => {
        const isDefaultPayment =
          account.associatedAccountReferenceId ===
          user.c1DefaultPaymentMethodAccountId;
        return (
          <div>
            <Container className={classes.paymentContainer}>
              <CreditCard
                selectedPayment={selectedPaymentMethod}
                handleSelectPayment={handleSelectPaymentMethod}
                account={account}
                isDefaultPayment={isDefaultPayment}
                isSingleCardUser={false}
                checked={
                  selectedDefault === account.associatedAccountReferenceId
                }
                showCheckBox={
                  selectedPaymentMethod.associatedAccountReferenceId ===
                    account.associatedAccountReferenceId &&
                  selectedPaymentMethod.associatedAccountReferenceId !==
                    user.c1DefaultPaymentMethodAccountId
                }
                onSelect={handleSelectDefault}
                type="editView"
                isReservation={true}
              />
            </Container>
            <Divider className={classes.paymentDivider} />
          </div>
        );
      })}
      <Container>
        <Button
          classes={{ root: classes.ctaButton }}
          onClick={handleSave}
          disabled={!selectedPaymentMethod}>
          {RESERVATION_PAYMENT_EDIT_TEXT}
        </Button>
      </Container>
    </div>
  );
};

export default EditPaymentMethod;
