import * as types from './HomePageTypes';

export const actionGetHomePageRequest = ({ marketId, forceCall = false }) => ({
  type: types.GET_HOME_PAGE_REQUEST,
  payload: { marketId, forceCall },
});

export const actionGetHomePageSuccess = (payload) => ({
  type: types.GET_HOME_PAGE_SUCCESS,
  payload,
});

export const actionGetHomePageFail = (payload) => ({
  type: types.GET_HOME_PAGE_FAIL,
  payload,
});

export const actionSetHomePageCached = (payload) => ({
  type: types.SET_HOME_PAGE_CACHED,
  payload,
});

export const actionSetHomePage = (payload) => ({
  type: types.SET_HOME_PAGE,
  payload,
});
