import { useEffect, useRef } from 'react';

const useCustomIntersectionObserver = (callback, options) => {
  const elementRef = useRef(null);

  useEffect(() => {
    const currentElement = elementRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback(entry);
          if (options?.triggerOnce) {
            observer.unobserve(entry.target);
          }
        }
      });
    }, options);

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [callback, options]);

  return elementRef;
};

export default useCustomIntersectionObserver;
