import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import ErrorRedirectButton from '../../components/core/ErrorRedirectButton';
import ErrorSnippet from '../../components/core/ErrorSnippet';
import PageSection from '../../components/core/PageSection';
import { actionResetErrorState } from '../../store/Error/ErrorAction';
import { useComponentWillUnmount } from '../../utils/useComponentWillUnmount';
import { ERROR_PAGE_TYPES } from '../../utils/constants/ErrorPageTypes';
import { useAuth } from '../../utils/useAuth';
import ErrorCloseButton from '../../components/core/ErrorCloseButton';
import ErrorSignoutButton from '../../components/core/ErrorSignoutButton';
import { trackErrorPageView } from '../../utils/useOneTag';
import { noticeError } from '../../utils/newRelic';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    overflow: 'hidden',
    right: 0,
    top: 0,
    minHeight: '60vh',
  },
  container: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
}));

const DefaultError = ({ type: errorTypeProp, showButton: showButtonProp }) => {
  const classes = useStyles();
  const { errorType } = useSelector((state) => state?.error);
  const { user } = useAuth();
  const location = useLocation();

  const [showButton, setShowButton] = useState(showButtonProp ?? false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    let _error = ERROR_PAGE_TYPES[errorType ?? errorTypeProp];
    if (_error == null) {
      _error = ERROR_PAGE_TYPES.PAGE_NOT_FOUND;
      if (user.session.isAuthenticated) {
        noticeError(_error.value, { path: location.pathname })
      }
    } else if (_error.value === ERROR_PAGE_TYPES.UNAUTHORIZED.value) {
      trackErrorPageView(_error);
      history.push('/sign-out');
      return;
    } else if (
      _error.value === ERROR_PAGE_TYPES.MAINTENANCE.value &&
      !user.session.isAuthenticated
    ) {
      _error = ERROR_PAGE_TYPES.MAINTENANCE_NO_SIGNOUT;
    }
    // Use GENERIC_NO_BUTTON error page type instead of GENERIC if on home page
    if (
      location.pathname === '/' &&
      _error.value === ERROR_PAGE_TYPES.GENERIC.value
    ) {
      _error = ERROR_PAGE_TYPES.GENERIC_NO_BUTTON;
    }
    trackErrorPageView(_error);
    setError(_error);
  }, [
    errorType,
    errorTypeProp,
    user.session.isAuthenticated,
    location.pathname,
    history,
  ]);

  useEffect(() => {
    if (error?.buttonText != null && error?.buttonUrl != null) {
      setShowButton(true);
    }
  }, [
      error,
      user.session.isAuthenticated
  ]);

  useComponentWillUnmount(() => {
    dispatch(actionResetErrorState());
  });

  return (
    error != null && (
      <PageSection className={classes.root} variant="grid">
        {error.onCloseUrl != null && (
          <ErrorCloseButton onCloseUrl={error.onCloseUrl} />
        )}
        <Grid className={classes.container} item xs={12} sm={6} xl={4}>
          <Box role="status">
            <ErrorSnippet type={error.value} />
          </Box>
          {showButton && (
            <ErrorRedirectButton
              className={classes.button}
              type={error.value}
            />
          )}
          {error.shouldShowSignoutButton && <ErrorSignoutButton />}
        </Grid>
      </PageSection>
    )
  );
};

export default DefaultError;
