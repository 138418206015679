import { Grid } from '@material-ui/core';
import Information from './Information';
import Divider from '../../components/overrides/Divider';
import ContactInfo from './ContactInfo';
import { makeStyles } from '@material-ui/styles';
import DetailedLayout from './DetailedLayout';
import { TIME_AVAILABILITY_TYPES } from '../venueAvailability/timeAvailabilityTypes';

const useStyles = makeStyles((theme) => ({
  desktopContainer: {
    width: '100%',
  },
  desktopContent: {
    paddingRight: theme.spacing(6),
  },
  desktopSidebar: {
    paddingRight: theme.spacing(6),
    display: 'inline-block',
  },
  desktopDivider: {
    marginLeft: theme.spacing(7),
    width: ({ type }) => {
      if (type === TIME_AVAILABILITY_TYPES.HORIZONTAL)
        return 'calc(100% - 56px)';
    },
  },
}));

const DesktopVenueLayout = ({
  venue,
  handleClick,
  date,
  time,
  user,
  guests,
  list,
  loadingAvailability,
  hasRelatedEvents = false,
  eventList,
}) => {
  const classes = useStyles({ type: TIME_AVAILABILITY_TYPES.HORIZONTAL });

  return (
    <>
      <Grid item container xl={8} className={classes.desktopContent}>
        <main id="main" className={classes.desktopContainer}>
          <Information venue={venue} />
          <Divider />
          <DetailedLayout
            list={list}
            venue={venue}
            guests={guests}
            user={user}
            time={time}
            date={date}
            loadingAvailability={loadingAvailability}
            handleClick={handleClick}
            eventList={hasRelatedEvents && eventList}
          />
        </main>
      </Grid>

      <Grid item container xl={4} className={classes.desktopSidebar}>
        <section aria-label="contact information">
          <ContactInfo venue={venue} />
        </section>
      </Grid>
    </>
  );
};

export default DesktopVenueLayout;
