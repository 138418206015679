import React, { useState, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Welcome from './Welcome';
import Location from './Location';
import { useAuth } from '../../utils/useAuth';
import { useHistory, useLocation } from 'react-router';
import { DINING_LOGO_ALT_TEXT, SELECT_BUTTON_LABEL } from '../../assets/copy';
import { getPermalinkRouteRedirectLocation } from './onboardingHelpers';
import useOnboarding from '../../utils/useOnboarding';
import {
  getPermalinkRouteRedirect,
  clearPermalinkRouteRedirect,
  clearPermalinkSlug,
} from '../../utils/sessionStorageHelper';
import Transition from '../../components/animation/Transition';
import LightLogoSVG from '../../assets/icons/logo-white.svg';
import DarkLogoSVG from '../../assets/icons/logo-dark.svg';
import { TABLET_BREAKPOINT } from './Layout';
import { useViewport } from '../../utils/useViewport';
import { Helmet } from 'react-helmet';
import OnboardingWelcomeBg from '../../assets/images/seville-day3-179.jpeg';
import OnboardingLocationBg from '../../assets/images/napasonoma-day1-232.jpeg';

const INITIAL_DELAY_MS = 750;
const FADE_DURATION_MS = 750;

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.beacon.surf,
    color: theme.palette.beacon.midnight,
    display: 'flex',
    [theme.breakpoints.up('xl')]: {
      height: '100vh',
    },
    minHeight: '100vh',
  },

  logoImage: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(4.5),
    zIndex: 99,
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      left: theme.spacing(6),
      top: theme.spacing(6),
    },
  },

  fadeAnimation: {
    '&-enter-active': {
      opacity: 0,
    },
    '&-enter-done': {
      opacity: 1,
      transition: `all ${FADE_DURATION_MS}ms`,
    },
    '&-exit': {
      opacity: 1,
    },
    '&-exit-active': {
      opacity: 0,
      transition: `all ${FADE_DURATION_MS}ms`,
    },
  },
}));

const Onboarding = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const markets = useSelector((state) => state.markets);
  const [list, setList] = useState(markets.list);
  const [shouldOnboard, setShouldOnboard] = useState(true);
  const { handleRestaurant } = useOnboarding();

  const {
    user,
    signinWithJwt,
    searchParams: { assertion },
  } = useAuth();

  useEffect(() => {
    signinWithJwt(assertion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user == null) return;
    if (!user.session?.isAuthenticated) return;

    //clear session storage for redirection when user gets to the FTUX
    if (getPermalinkRouteRedirect() === '/onboarding') {
      clearPermalinkRouteRedirect();
    }

    // Skip onboarding on load if user already selected default city
    if (user.marketId != null && activeStep === 0) {
      setShouldOnboard(false);
      history.push({
        pathname: getPermalinkRouteRedirectLocation(),
        search: location.search,
      });
    }
  }, [activeStep, user, history, location]);

  useEffect(() => {
    try {
      const result = {
        id: '0',
        name: SELECT_BUTTON_LABEL,
      };
      setList([result, ...markets.list]);
    } catch (e) {
      console.error(e);
    }
  }, [markets?.list]);

  useEffect(() => {
    document.body.style.backgroundColor = '#10253F';
    return () => {
      document.body.style.backgroundColor = 'white';
      clearPermalinkSlug();
      clearPermalinkRouteRedirect();
    };
  }, []);

  const { width } = useViewport();

  return (
    shouldOnboard &&
    user.session.isAuthenticated && (
      <>
        <Helmet>
          <link rel="preload" as="image" href={LightLogoSVG} />
          <link rel="preload" as="image" href={DarkLogoSVG} />
          <link rel="preload" as="image" href={OnboardingWelcomeBg} />
          <link rel="preload" as="image" href={OnboardingLocationBg} />
        </Helmet>
        <Box className={classes.root}>
          <Transition
            active={true}
            delay={INITIAL_DELAY_MS}
            timeout={0}
            classNames={classes.fadeAnimation}
            onExited={() => setActiveStep(1)}>
            <img
              src={width < TABLET_BREAKPOINT ? LightLogoSVG : DarkLogoSVG}
              className={classes.logoImage}
              alt={DINING_LOGO_ALT_TEXT}
            />
          </Transition>
          <Transition
            active={activeStep === 0}
            delay={INITIAL_DELAY_MS}
            timeout={{
              enter: 0,
              appear: 0,
              exit: FADE_DURATION_MS,
            }}
            classNames={classes.fadeAnimation}
            onExited={() => setActiveStep(1)}>
            <Welcome handleNext={() => setActiveStep(-1)} />
          </Transition>
          <Transition
            active={activeStep === 1}
            timeout={{
              enter: 0,
              appear: 0,
              exit: 0,
            }}
            classNames={classes.fadeAnimation}>
            <Location handleNext={handleRestaurant} list={list} />
          </Transition>
        </Box>
      </>
    )
  );
};

export default Onboarding;
