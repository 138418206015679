import { put, takeLatest, select } from 'redux-saga/effects';

import { getHomePage } from '../../graphql/customQueries';

import * as ACTIONS from './HomePageAction';
import * as TYPES from './HomePageTypes';
import { callGraphqlWithToken } from '../helpers';

export function* getHomePageRequestHandler(data) {
  const { homePage, featureToggle, user } = yield select((state) => state);
  // Only pass these feature flags to the home page resolver, if they are enabled
  const featureFlagsSet = new Set([
    'dining-cx-refresh-randomization-tier-1',
    'dining-cx-refresh-randomization-signature',
    'dining-cx-refresh-randomization-curated',
  ]);
  // Convert features map to an array of feature names to pass to home page resolver
  const featureFlagsArray = [...featureToggle.features]
    .filter(
      ([featureName, isEnabled]) =>
        featureFlagsSet.has(featureName) && isEnabled
    )
    .map(([featureName, _isEnabled]) => featureName);

  // If forceCall is enabled or the state of the homePage is null. The saga would call the DiningPortalHomePageService
  if (data.payload.forceCall || homePage.homePage === null) {
    try {
      const result = yield callGraphqlWithToken({
        query: getHomePage,
        variables: {
          marketId: data.payload.marketId,
          featureFlags: featureFlagsArray,
        },
      });
      yield put(ACTIONS.actionGetHomePageSuccess(result.data.getHomePage));
      // cache the homePage response for the market so we don't have to call the home page resolver again
      yield put(
        ACTIONS.actionSetHomePageCached({
          marketId: data.payload.marketId,
          homePage: result.data.getHomePage,
          ccid: user.user?.session?.ccid,
        })
      );
    } catch (e) {
      console.error(e);
      yield put(ACTIONS.actionGetHomePageFail(e));
    }
  } else {
    // Just return the current state
    yield put(ACTIONS.actionGetHomePageSuccess(homePage.homePage));
  }
}

export default function* homePageSaga() {
  yield takeLatest(TYPES.GET_HOME_PAGE_REQUEST, getHomePageRequestHandler);
}
