import { put, takeLatest } from 'redux-saga/effects';
import { dispatchError } from '../helpers';
import { config } from '../../config';
import { checkMaintenanceWindow } from '../../store/helpers';

import * as ACTIONS from './ConfigAction';
import * as TYPES from './ConfigTypes';

export function* getConfigRequestHandler() {
  try {
    yield checkMaintenanceWindow();
    yield put(ACTIONS.actionGetConfigSuccess(config));
  } catch (error) {
    yield dispatchError(error.response?.data?.message ?? error.message, TYPES.GET_CONFIG_REQUEST);
    yield put(ACTIONS.actionGetConfigFail(error));
  }
}

export default function* configSaga() {
  yield takeLatest(TYPES.GET_CONFIG_REQUEST, getConfigRequestHandler);
}
