import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Info } from '@material-ui/icons/';
import { makeStyles } from '@material-ui/styles';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CalendarSelector from '../../components/core/CalendarSelector';
import Typography from '../../components/overrides/Typography';
import FilledButton from '../../components/core/FilledButton';
import PageSection from '../../components/core/PageSection';
import LargeCard from '../../components/core/Card/LargeCard';
import SmallCard from '../../components/core/Card/SmallCard';
import CloseButton from '../../components/core/CloseButton';
import CancelEditModal from '../../components/reserve/CancelEditModal';
import { useViewport } from '../../utils/useViewport';
import {
  actionSetDraftReservation,
  actionGetReservationRequest,
} from '../../store/Reservations/ReservationsAction';
import { sanitizeSelTime } from '../../utils/dateTime';

import {
  EDIT_EXISTING_RESERVATION_FIND_TIMESLOT,
  RESERVATION_EDIT_TITLE,
  RESERVATION_EDIT_CURRENT_LABEL,
  RESERVATION_CHANGES_AND_UPDATE_CONTACT_RESTAURANT_TEXT,
  CLOSE_BUTTON_DESCRIPTION,
  CLOSE_BUTTON_LABEL,
} from '../../assets/copy';
import { PAYMENT_RULE_TYPE } from './helpers/paymentAvailabilityTypes';
import moment from 'moment';
import '../../components/core/Calendar/css/react-datetime.css';
import { WIDTH_BREAKPOINT } from '../../utils/constants/Breakpoints';
import { RESERVATION_TYPE } from '../../utils/constants/Reservation';
import SelectedDateAvailability from '../venue/SelectedDayAvailability';
import { generateGetDateRangeAvailabilityInput } from '../../utils/generateAvailabilityInput';
import {
  getDaysInMonthFromDate,
  getRemainingDaysInMonth,
} from '../../utils/dayMonthHelper';
import { actionGetDateRangeAvailabilityRequest } from '../../store/Availability/AvailabilityAction';
import { TIME_AVAILABILITY_TYPES } from '../venueAvailability/timeAvailabilityTypes';
import ReservationSheet from '../../components/core/Sheet/ReservationSheet';
import SignatureCollectionDescription from '../../components/spoon/SignatureCollectionDescription';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    alignItems: 'center',
    boxSizing: 'border-box',
    flexDirection: 'column',
    padding: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      paddingTop: theme.spacing(4),
    },
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
    scrollBehavior: 'smooth',
  },
  calendar: {
    marginTop: theme.spacing(1.5),
    flexWrap: 'wrap',
    display: 'flex',
    width: '100%',
  },
  closeContainer: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(4),
    },
  },
  close: {
    padding: 0,
    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(1.5),
    },
  },
  button: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      maxWidth: 186,
      padding: 0,
    },
  },
  venueButton: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('xl')]: {
      maxWidth: 250,
      padding: 0,
    },
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    '& .MuiOutlinedInput-input': {
      ...theme.typography.medium1Normal,
      boxSizing: 'border-box',
      height: '100%',
      padding: '11px 14px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 4,
      height: 48,
    },
  },
  infoIcon: {
    color: theme.palette.text.primary,
  },
  image: {
    marginRight: theme.spacing(2),
    top: `calc(40%)`,
  },
  leftBorder: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: '323px',
    },
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  leftGridSection: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: 350,
      marginRight: theme.spacing(8),
    },
  },
  subheader: {
    [theme.breakpoints.up('xl')]: {
      marginBottom: theme.spacing(2),
    },
  },
  message: {
    textAlign: 'left',
    color: theme.palette.error.main,
    fontSize: 12,
    marginBottom: theme.spacing(0.4),
  },
  hiddenStyles: {
    position: 'absolute',
    overflow: 'hidden',
    clip: 'rect(0 0 0 0)',
    height: 1,
    width: 1,
    margin: -1,
    padding: 0,
    border: 0,
  },
  contentColumn: {
    [theme.breakpoints.up('xl')]: {
      flex: '1 1 100%',
    },
  },
  dateSectionBorder: {
    '& div input': {
      border: `2px solid ${theme.palette.red[50]}!important`,
    },
  },
  dateSection: {
    pading: `{theme.spacing(0)!important}`,
    [theme.breakpoints.up('xl')]: {
      '& .rdt p.MuiTypography-root': {
        marginBottom: 1,
      },
    },
  },
}));

const EditReserve = () => {
  const classes = useStyles();
  const { width } = useViewport();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selDateOldFlow, setSelectedDateOldFlow] = useState();
  const [selGuestsOldFlow, setSelGuestsOldFlow] = useState();
  const [selTimeOldFlow, setSelTimeOldFlow] = useState();

  // Still need this resi in new flow
  const { reservation } = useSelector((state) => state.reservations);

  // Old stuff

  useEffect(() => {
    if (reservation) {
      if (reservation.paymentRule !== PAYMENT_RULE_TYPE.NONE) {
        // if paymentRule is either "save_for_later" or "advance_payment" redirect the user out
        history.replace('/reservations');
      }
      const initialYear = moment(`${reservation.date}`).format('YYYY/MM/DD');
      const tempDate = moment(`${initialYear} ${reservation.time}`);
      setSelectedDateOldFlow(tempDate.format('YYYY-MM-DD'));
      setSelGuestsOldFlow(reservation.guests);
      setSelTimeOldFlow(sanitizeSelTime(reservation.time));
    }
  }, [reservation, history]);

  const handleSeeVenueAvailability = () => {
    dispatch(
      actionSetDraftReservation({
        ...reservation,
        date: selDateOldFlow,
        time: selTimeOldFlow,
        guests: selGuestsOldFlow,
      })
    );

    history.push('/reserve/edit/check-availability', {
      date: selDateOldFlow,
      time: selTimeOldFlow,
      guests: selGuestsOldFlow,
    });
  };

  const handleToggleEditModal = () => {
    setModalOpen((isOpen) => !isOpen);
  };

  // NEW LOGIC - CALENDAR

  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    const currentDate = new Date();
    const input = generateGetDateRangeAvailabilityInput(
      reservation.venue,
      currentDate,
      reservation.guests,
      getRemainingDaysInMonth(currentDate) +
        getDaysInMonthFromDate(currentDate, 1)
    );
    dispatch(actionGetDateRangeAvailabilityRequest(input));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    selectedDate,
    selectedTime,
    selectedGuests,
    loadingAvailability,
    list,
  } = useSelector((state) => state.availability);

  useEffect(() => {
    if (!reservation) {
      dispatch(actionGetReservationRequest({ id: id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleCancelEdit = () => {
    setModalOpen(false);
    history.push(`/reservations/${id}`);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const { navigationMenuVisible } = useSelector((state) => state.appData);

  const renderPrepaidView = () => (
    <>
      <Box pt={1} className={classes.alertContainer}>
        <Box className={classes.leftBorder}>
          <Alert
            severity="info"
            iconMapping={{
              info: <Info className={classes.infoIcon} />,
            }}>
            <Typography variant="small2Normal" gutterBottom>
              {RESERVATION_CHANGES_AND_UPDATE_CONTACT_RESTAURANT_TEXT}
            </Typography>
          </Alert>
        </Box>
      </Box>
      <Box>
        <FilledButton
          text="See Venue Availability"
          variant="medium2Normal"
          onClick={handleSeeVenueAvailability}
          role="link"
          style={classes.venueButton}
        />
      </Box>
    </>
  );

  const renderEditView = () => (
    <>
      <CalendarSelector
        venue={reservation.venue}
        guests={selectedGuests}
        time={selectedTime}
        date={selectedDate}
        loadingAvailability={loadingAvailability}
      />
      <SelectedDateAvailability
        list={list}
        venue={reservation.venue}
        guests={selectedGuests}
        user={user}
        time={selectedTime}
        date={selectedDate}
        loadingAvailability={loadingAvailability}
        type={TIME_AVAILABILITY_TYPES.HORIZONTAL_EDIT_RESERVATION}
        resType={RESERVATION_TYPE.UPDATE}
      />
    </>
  );

  const renderEditReservationSection = () =>
    reservation && (
      <Grid item xs={12} xl={5}>
        <Typography
          className={classes.subheader}
          variant={
            width >= WIDTH_BREAKPOINT ? 'medium2Semibold' : 'medium1Semibold'
          }
          component="h2">
          {EDIT_EXISTING_RESERVATION_FIND_TIMESLOT}
        </Typography>
        {reservation.isPrepaid ? renderPrepaidView() : renderEditView()}
      </Grid>
    );

  const closeSVGDimension = width >= WIDTH_BREAKPOINT ? 21 : 16;

  return (
    !navigationMenuVisible && (
      <PageSection className={classes.root} variant="grid">
        <ReservationSheet type={RESERVATION_TYPE.UPDATE} />
        <Grid item container flexWrap="wrap" xl={10}>
          <Grid item xs={12}>
            <Box mb={width >= WIDTH_BREAKPOINT ? 3 : 2}>
              <Box
                className={classes.closeContainer}
                display="flex"
                justifyContent="flex-end">
                <CloseButton
                  className={classes.close}
                  close={handleToggleEditModal}
                  description={CLOSE_BUTTON_DESCRIPTION}
                  fill="#0D74AF"
                  label={CLOSE_BUTTON_LABEL}
                  width={closeSVGDimension}
                  height={closeSVGDimension}
                  disableRipple={true}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Typography
                  component="h1"
                  aria-live="polite"
                  variant={
                    width >= WIDTH_BREAKPOINT ? 'large1Light' : 'medium3Light'
                  }>
                  {RESERVATION_EDIT_TITLE}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} xl={5} className={classes.leftGridSection}>
            <Typography
              variant={
                width >= WIDTH_BREAKPOINT
                  ? 'medium2Semibold'
                  : 'medium1Semibold'
              }
              className={classes.subTitle}
              component="h2">
              {RESERVATION_EDIT_CURRENT_LABEL}
            </Typography>
            <Box pt={1} pb={4}>
              {reservation &&
                (width >= WIDTH_BREAKPOINT ? (
                  <LargeCard
                    venue={reservation.venue}
                    reservation={reservation}
                    showActionLinks={false}
                    showSeeVenueLink={false}
                  />
                ) : (
                  <SmallCard
                    reservation={reservation}
                    venue={reservation.venue}
                  />
                ))}
            </Box>
            {reservation.venue?.isSignatureCollection && (
              <Box mb={4}>
                <SignatureCollectionDescription />
              </Box>
            )}
          </Grid>
          {renderEditReservationSection()}
        </Grid>
        <CancelEditModal
          modalOpen={modalOpen}
          handleCancelEdit={handleCancelEdit}
          handleToggleEditModal={handleToggleEditModal}
        />
      </PageSection>
    )
  );
};

export default EditReserve;
