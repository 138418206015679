import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useAuth } from '../../utils/useAuth';
import CommonLoading from '../common/CommonLoading';
import { clearReservationRoute, getReservationRoute, setReservationRoute, setReservationPageBackRoute, setReservationSource } from '../../utils/sessionStorageHelper';
import { actionSetDraftReservation } from '../../store/Reservations/ReservationsAction';
import { generateAvailabilityInput } from '../../utils/generateAvailabilityInput';
import { actionGetAvailabilityRequest } from '../../store/Availability/AvailabilityAction';
import { filterTimeslots } from '../../utils/availabilityHelper';
import { actionGetVenueBySlugRequest } from '../../store/Venues/VenuesAction';
import { useSaveUserCallback } from '../../components/profile/helpers';
import { noticeError } from '../../utils/newRelic';
import {
  convertUserAllergies,
  convertUserDiets
} from '../../utils/reservationFilters';

const ReservePermalink = () => {
  const {
    user,
    signinWithJwt,
    searchParams: { assertion },
  } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { availability } = useSelector((state) => state.availability);
  const { draftReservation } = useSelector((state) => state.reservations);
  const { venue } = useSelector((state) => state.venues);
  const queryRef = useRef(null);
  const saveUser = useSaveUserCallback(user);
  const [error, setError] = useState(null)

  useEffect(() => {
    signinWithJwt(assertion);
    queryRef.current = location.search;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!user.session.isAuthenticated) return;
    clearReservationRoute();

    const searchParams = new URLSearchParams(queryRef.current);
    const slug = searchParams.get('slug');
    const sevenRoomsVenueId = searchParams.get('sevenRoomsVenueId');
    const date = searchParams.get('date');
    const timeSlot = searchParams.get('timeslot');
    const slotOptionId = searchParams.get('slotOptionId')
    const guests = searchParams.get('guests');
    const experienceId = searchParams.get('experienceId');

    if (slug) {
      dispatch(actionGetVenueBySlugRequest(slug));
    } else {
      setReservationRoute('/')
      noticeError('Missing slug for reserve permalink',
          { path: searchParams.toString() });
      return;
    }

    if (!(sevenRoomsVenueId && date && timeSlot && slotOptionId && guests)) {
      setError(true);
      noticeError('Invalid query parameters for ' + slug,
          { path: searchParams.toString() });
      return;
    }
    const venueObj = { sevenRoomsVenueId, experienceId };
    const input = generateAvailabilityInput(
        venueObj,
        date,
        timeSlot,
        guests,
        false,
        true
    );

    let draftReservation = {
      venueObj,
      date,
      time: timeSlot,
      slotOptionId,
      guests,
      experienceId
    }

    if (input.date !== "Invalid date") {
      dispatch(actionGetAvailabilityRequest(input));
    }

    if (timeSlot) {
      dispatch(actionSetDraftReservation(draftReservation));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.session.isAuthenticated]);

  useEffect(() => {
    if (venue?.id && error) {
      setReservationRoute(`/venue/${venue?.id}`);
    }
  }, [error, venue?.id]);

  useEffect(() => {
    if (!availability) return;
    const timeslot = filterTimeslots(availability.timeslots,
        draftReservation.time, draftReservation.slotOptionId);
    const isAvailable = timeslot ? true : false;

    // Skip FTUX
    if (venue && user?.marketId === null) {
      const payload = {
        marketId: venue?.marketId,
      };
      saveUser(payload);
    }

    const draftReservationCopy = {
      venue,
      userId: user.id,
      c1CustRefId: user.c1CustRefId,
      defaultTipAmount: user.defaultTipAmount,
      allergies: convertUserAllergies(user.allergies),
      diets: convertUserDiets(user.diets),
      notes: user.notes || '',
      emailAddress: user.emailAddress,
      specialOccasions: [],
      phoneNumber: user.phoneNumber,
      firstName: user.firstName,
      lastName: user.lastName,
      ...timeslot,
      date: draftReservation.date,
      time: draftReservation.time,
      guests: draftReservation.guests,
      bookingPolicy: timeslot?.bookingPolicy || venue?.bookingPolicy,
      isCardholderTable: timeslot?.isExclusive || false,
      isAvailable,
    };

    dispatch(actionSetDraftReservation(draftReservationCopy));
    setReservationSource('eater');
    setReservationRoute('/reserve');
    setReservationPageBackRoute(`/venue/${venue?.id}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availability, venue]);

  useEffect(() => {
    if (!getReservationRoute()) {
      return <CommonLoading active={true} embedded={true} />
    }
    history.replace({
      pathname: getReservationRoute()
    });
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [history, getReservationRoute()]);

  return (
      !user.session.isAuthenticated && (
          <CommonLoading active={!user.session.isAuthenticated} embedded={true} />
      )
  );
}

export default ReservePermalink;
