import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  CircularProgress,
  Container,
  Link,
  makeStyles,
} from '@material-ui/core';
import Alert from '../../core/Alert';
import ReservationCard from '../../core/Card/ReservationCard';
import Typography from '../../overrides/Typography';
import TermsOfService from '../../core/TermsOfService';
import {
  BOOKING_POLICY_LABEL,
  PROFILE_DIETARY_PREFERENCES_SECTION_LABEL,
  PAYMENT_POLICY_LABEL,
  EDIT_BUTTON_TEXT,
  NOTES_PLACEHOLDER,
  RESERVE_EDIT_SPECIAL_REQUESTS,
  PROFILE_CONTACT_DETAILS_SUBTITLE,
  PROFILE_DIETARY_PREFERENCES_DIETS_LABEL,
  PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL,
  RESERVE_NOW_TEXT,
  PROFILE_DIETARY_PREFERENCES_ALLERGIES_NONE_SELECTED,
  PROFILE_DIETARY_PREFERENCES_DIETS_NONE_SELECTED,
  SPECIAL_OCCASION_LABEL,
  DINING_OPTION_TITLE,
  PROGRESS_BAR_LABEL,
  SPECIAL_REQUESTS_SECTION_LABEL,
  LOADING_TEXT,
  RESERVE_NO_AVAILABILITY,
  RESERVE_VIEW_MORE_AVAILABILITY,
  PAYMENT_METHODS_TITLE,
  PROFILE_PHONE_LABEL,
  RESERVE_PRESELECTED_SPECIAL_OCCASION_TEXT,
} from '../../../assets/copy';
import { useSelector } from 'react-redux';
import { Button, TextButton } from '../Button';
import { Parser as HtmlToReactParser } from 'html-to-react';
import { TextArea } from '../TextArea';
import CreditCard from '../CreditCard';
import useReservationMethods from '../../../utils/useReservationMethods';
import { trackClickWithDescription } from '../../../utils/useOneTag';
import SelectText from '../../core/SelectText';
import { formatPhone } from '../../../utils/formatPhone';
import ReservationCharges from '../../../pages/reserve/ReservationCharges';
import { useReserve } from '../../../utils/useReserve';
import StripePaymentForm from '../../core/Stripe/StripePaymentForm';
import { PAYMENT_RULE_TYPE } from '../../../pages/reserve/helpers/paymentAvailabilityTypes';
import { validatePhoneNumber } from '../../../utils/validatePhoneNumber';
import { ModalLoading } from './ModalLoading';
import { FETCH_STATUS } from '../../../utils/constants/FetchStatus';
import { ALERT_VARIANTS } from '../../../utils/constants/AlertVariants';
import FirstTimePaymentOptionsSelect from '../../../pages/reserve/FirstTimePaymentOptionsSelect';
import { PAYMENT_METHOD_COMPONENT_TYPES } from '../../../pages/profile/paymentComponentTypes';
import PhoneField from '../../../pages/reserve/PhoneField';
import { debounce } from 'lodash';
import { useGetPaymentMethod } from '../../../pages/profile/useGetPaymentMethod';
import InfoBlock from '../../core/InfoBlock';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  ctaContainer: {
    position: 'sticky',
    bottom: 0,
    width: '100%',
    backgroundColor: theme.palette.spoon.white[100],
    padding: theme.spacing(3),
    boxShadow: `0 -2px 8px rgba(0, 0, 0, .2)`,
  },
  ctaButton: {
    fontSize: 16,
    lineHeight: '24px',
    padding: theme.spacing(1.5),
    width: '100%',
  },
  contentContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    overflowX: 'hidden',
  },
  fullWidth: {
    width: '100%',
  },
  sectionContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  sectionTopRow: {
    height: theme.spacing(4),
  },
  flexRow: {
    display: 'flex',
    columnGap: theme.spacing(1),
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  lightText: {
    color: theme.palette.digitalGray[60],
  },
  dietSubheading: {
    padding: theme.spacing(1, 0),
  },
  editButtonRoot: {
    padding: theme.spacing(0.75, 2),
  },
  termsOfService: {
    borderTop: `1px solid ${theme.palette.digitalGray[20]}`,
    padding: theme.spacing(2, 0, 0.5),
    '& > *': {
      color: theme.palette.digitalGray[110],
      fontSize: 12,
    },
  },
  termsOfServiceLink: {
    color: theme.palette.interactionBlue[50],
    textDecoration: 'underline',
  },
  policy: {
    '& > *': {
      fontSize: 12,
      color: 'blue',
    },
  },
  occasionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  viewMoreAvailability: {
    textDecoration: 'underline',
    color: 'black',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  fieldset: {
    border: 'none',
    paddingInline: 'unset',
    paddingBlock: 'unset',
    margin: 'none',
  },
  legend: {
    paddingBottom: theme.spacing(2),
    paddingBlock: 'unset',
    paddingInline: 'unset',
  },
}));

const Payment = ({
  paymentRule,
  shouldShowStripeForm,
  stripeAlertMessage,
  stripeProperties,
  onEditPaymentMethod,
  venue,
  user,
}) => {
  const classes = useStyles();

  const isSingleCardUser = user?.associatedAccounts?.length === 1;
  const hasDefaultPaymentMethod = !!user?.c1DefaultPaymentMethodAccountId;
  const defaultAccount = useGetPaymentMethod(
    PAYMENT_METHOD_COMPONENT_TYPES.RESERVE
  );

  if (!paymentRule) {
    return null;
  }

  if (shouldShowStripeForm) {
    return (
      <>
        <StripePaymentForm
          stripeAlertMessage={stripeAlertMessage}
          isModal={true}
          {...stripeProperties}
        />
      </>
    );
  }

  return (
    <div className={classes.sectionContainer}>
      <div className={clsx(classes.flexRow, classes.sectionTopRow)}>
        <Typography variant="medium1Semibold" component="h3">
          {PAYMENT_METHODS_TITLE}
        </Typography>
        {!isSingleCardUser && hasDefaultPaymentMethod && (
          <TextButton
            classes={{ root: classes.editButtonRoot }}
            onClick={() => {
              trackClickWithDescription(
                'bottom-sheet-edit-payment-method',
                'Button',
                venue?.name
              );
              onEditPaymentMethod();
            }}>
            {EDIT_BUTTON_TEXT}
          </TextButton>
        )}
      </div>
      {(hasDefaultPaymentMethod || isSingleCardUser) && (
        <CreditCard
          cardArtUrl={defaultAccount.accountDetails.cardArtUrl}
          productName={defaultAccount.accountDetails.product}
          lastFourCardNumber={defaultAccount.accountDetails.lastFourCardNumber}
          variant="compact"
        />
      )}
      {!isSingleCardUser && !hasDefaultPaymentMethod && (
        <FirstTimePaymentOptionsSelect
          user={user}
          type={PAYMENT_METHOD_COMPONENT_TYPES.RESERVE}
        />
      )}
    </div>
  );
};

const MAX_NOTE_LENGTH = 500;

export const ConfirmReservation = ({
  onDietEdit,
  onConfirm,
  onEditPaymentMethod,
  onNoAvailabilityLinkClick,
}) => {
  const classes = useStyles();
  const htmlToReactParser = new HtmlToReactParser();
  const { user } = useSelector((state) => state.user);
  const { specialOccasions } = useSelector((state) => state.appData);

  const {
    draftReservation,
    handleReserve,
    isPaymentDisabled,
    paymentLoading,
    paymentRule,
    redirectToConfirmPage,
    reservationChargesProperties,
    reservationFetchStatus,
    shouldShowLoader,
    shouldShowStripeForm,
    showAlert,
    showInfoBlock,
    stripeAlertMessage,
    stripeProperties,
    venuePaymentPolicy,
  } = useReserve({ isModal: true });

  const [notesText, setNotesText] = React.useState(
    draftReservation.notes || ''
  );
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const { updateDraftReservation } = useReservationMethods();

  const isTestUser = user.isTest;
  const isTestVenue = draftReservation.venue.isTest;
  const shouldBlockTestUserSubmit = isTestUser && !isTestVenue;
  const shouldDisableSubmitButton =
    !isValidPhoneNumber ||
    shouldBlockTestUserSubmit ||
    paymentLoading ||
    isPaymentDisabled;

  const handleSelectOccasion = (item) => {
    let updatedOccasionsList = [];
    if (
      draftReservation.specialOccasions?.some(
        (occasion) => occasion.id === item.id
      )
    ) {
      updatedOccasionsList = draftReservation.specialOccasions.filter(
        (occasion) => occasion.id !== item.id
      );
    } else {
      updatedOccasionsList = [
        ...(draftReservation.specialOccasions ?? []),
        item,
      ];
    }
    updateDraftReservation({
      specialOccasions: updatedOccasionsList,
    });
  };

  useEffect(() => {
    setIsValidPhoneNumber(validatePhoneNumber(draftReservation?.phoneNumber));
  }, [draftReservation.phoneNumber]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveToDraft = useCallback(
    debounce((obj) => {
      updateDraftReservation(obj);
    }, 200),
    []
  );

  useEffect(() => {
    saveToDraft({ notes: notesText });
  }, [notesText, saveToDraft]);

  useEffect(() => {
    if (redirectToConfirmPage) {
      onConfirm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectToConfirmPage]);

  const reservationDate = new Date(draftReservation.realDateTime);

  /*
    Don't show the loader if using Stripe form, since Stripe requires card form
    element to be visible in DOM, and the loader would remove the form from the DOM
  */
  if (
    !showAlert &&
    (reservationFetchStatus === FETCH_STATUS.FETCHING ||
      (shouldShowLoader && !shouldShowStripeForm))
  ) {
    return (
      <Box aria-labelledby="loading-spinner" aria-live="assertive" role="alert">
        <ModalLoading />
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <Container className={classes.contentContainer}>
        <ReservationCard
          venueName={draftReservation.venue.name}
          imageUrl={draftReservation.venue.images[0]}
          reservationDate={reservationDate.toLocaleDateString('en-US', {
            weekday: 'short',
            month: 'long',
            day: 'numeric',
          })}
          reservationTime={draftReservation.time}
          guestCount={draftReservation.guests}
          isCardholderTable={
            draftReservation.isCardholderTable || draftReservation.isExclusive
          }
          classes={{ root: classes.fullWidth }}
        />
        {showAlert && (
          <Alert variant={ALERT_VARIANTS.ERROR}>
            <Box role="alert" tabIndex={0} aria-describedby="alertTitle">
              <Typography id="alertTitle">
                {RESERVE_NO_AVAILABILITY}{' '}
                <Link
                  className={classes.viewMoreAvailability}
                  onClick={onNoAvailabilityLinkClick}>
                  {RESERVE_VIEW_MORE_AVAILABILITY}
                </Link>
              </Typography>
            </Box>
          </Alert>
        )}
        <div className={classes.sectionContainer}>
          <div className={classes.sectionTopRow}>
            <Typography variant="medium1Semibold" component="h3">
              {PROFILE_CONTACT_DETAILS_SUBTITLE}
            </Typography>
          </div>
          <div className={classes.flexRow}>
            <Typography
              variant="medium1Normal"
              className={
                classes.lightText
              }>{`${user.firstName} ${user.lastName}`}</Typography>
            <Typography variant="medium1Normal" className={classes.lightText}>
              {user.emailAddress}
            </Typography>
          </div>
          {user.phoneNumber && (
            <Typography variant="medium1Normal" className={classes.lightText}>
              {formatPhone(user.phoneNumber)}
            </Typography>
          )}
        </div>

        {!user.phoneNumber && (
          <div className={classes.sectionContainer}>
            <PhoneField
              textInputProps={{
                label: PROFILE_PHONE_LABEL,
                labelProps: { variant: 'medium1Semibold' },
                inputProps: { inputMode: 'tel' },
              }}
            />
          </div>
        )}

        <div className={classes.sectionContainer}>
          <div className={clsx(classes.flexRow, classes.sectionTopRow)}>
            <Typography variant="medium1Semibold" component="h3">
              {PROFILE_DIETARY_PREFERENCES_SECTION_LABEL}
            </Typography>
            <TextButton
              classes={{ root: classes.editButtonRoot }}
              aria-label="Edit Dietary preferences"
              onClick={() => {
                trackClickWithDescription(
                  'bottom-sheet-edit-dietary-preferences',
                  'Button',
                  draftReservation.venue?.name
                );
                onDietEdit();
              }}>
              {EDIT_BUTTON_TEXT}
            </TextButton>
          </div>
          <Typography variant="small1Normal" className={classes.dietSubheading}>
            {PROFILE_DIETARY_PREFERENCES_DIETS_LABEL}
          </Typography>
          <Typography variant="medium1Normal" className={classes.lightText}>
            {draftReservation.diets.map((diet) => diet.name).join(', ') ||
              PROFILE_DIETARY_PREFERENCES_DIETS_NONE_SELECTED}
          </Typography>
          <Typography variant="small1Normal" className={classes.dietSubheading}>
            {PROFILE_DIETARY_PREFERENCES_ALLERGIES_LABEL}
          </Typography>
          <Typography variant="medium1Normal" className={classes.lightText}>
            {draftReservation.allergies
              .map((allergy) => allergy.name)
              .join(', ') ||
              PROFILE_DIETARY_PREFERENCES_ALLERGIES_NONE_SELECTED}
          </Typography>
        </div>
        <div className={classes.sectionContainer}>
          <fieldset className={classes.fieldset}>
            <legend className={classes.legend}>
              <Typography variant="medium1Semibold" component="h3">
                {SPECIAL_OCCASION_LABEL}
              </Typography>
            </legend>
            {showInfoBlock && (
              <Box mb={2} mt={-1}>
                <InfoBlock text={RESERVE_PRESELECTED_SPECIAL_OCCASION_TEXT} />
              </Box>
            )}
            <div className={classes.occasionsContainer}>
              {specialOccasions.map((item) => (
                <SelectText
                  key={`keytext-${item.id}`}
                  item={item}
                  list={draftReservation.specialOccasions}
                  onClick={() => handleSelectOccasion(item)}
                />
              ))}
            </div>
          </fieldset>
        </div>

        {draftReservation.slotName && (
          <div className={classes.sectionContainer}>
            <div className={classes.sectionTopRow}>
              <Typography variant="medium1Semibold" component="h3">
                {DINING_OPTION_TITLE}
              </Typography>
            </div>
            <Typography variant="small1Normal">
              {draftReservation.slotName}
            </Typography>
          </div>
        )}

        <div className={classes.sectionContainer}>
          <div className={classes.sectionTopRow}>
            <Typography variant="medium1Semibold" component="h3">
              {SPECIAL_REQUESTS_SECTION_LABEL}
            </Typography>
          </div>
          <TextArea
            label={RESERVE_EDIT_SPECIAL_REQUESTS}
            value={notesText}
            onChange={(e) => setNotesText(e.target.value)}
            placeholderText={NOTES_PLACEHOLDER}
            maxLength={MAX_NOTE_LENGTH}
          />
        </div>

        {paymentLoading ? (
          <Box align={'center'} mt={2} mb={2} aria-live="assertive">
            <CircularProgress
              align={'center'}
              role="progressbar"
              aria-label={PROGRESS_BAR_LABEL}
              title={LOADING_TEXT}
              aria-valuenow={0}
            />
          </Box>
        ) : (
          <>
            <ReservationCharges
              isModal={true}
              {...reservationChargesProperties}
            />
            <Payment
              paymentRule={paymentRule}
              shouldShowStripeForm={shouldShowStripeForm}
              stripeAlertMessage={stripeAlertMessage}
              stripeProperties={stripeProperties}
              onEditPaymentMethod={onEditPaymentMethod}
              associatedAccounts={user.associatedAccounts}
              venue={draftReservation.venue}
              user={user}
            />
          </>
        )}
        {draftReservation?.requiresCreditCard &&
          venuePaymentPolicy &&
          (paymentRule === PAYMENT_RULE_TYPE.ADVANCED_PAYMENT ||
            paymentRule === PAYMENT_RULE_TYPE.SAVE_FOR_LATER) && (
            <div className={classes.sectionContainer}>
              <div className={classes.sectionTopRow}>
                <Typography variant="medium1Semibold" component="h3">
                  {PAYMENT_POLICY_LABEL}
                </Typography>
              </div>
              <Typography variant="small2Normal">
                {htmlToReactParser.parse(venuePaymentPolicy)}
              </Typography>
            </div>
          )}

        {draftReservation?.bookingPolicy && (
          <div className={classes.sectionContainer}>
            <div className={classes.sectionTopRow}>
              <Typography variant="medium1Semibold" component="h3">
                {BOOKING_POLICY_LABEL}
              </Typography>
            </div>
            <Typography variant="small2Normal">
              {htmlToReactParser.parse(draftReservation.bookingPolicy)}
            </Typography>
          </div>
        )}

        <div className={classes.termsOfService}>
          <TermsOfService
            variant="small1Normal"
            quotedText={RESERVE_NOW_TEXT}
            classes={{ link: classes.termsOfServiceLink }}
          />
        </div>
      </Container>
      <Container className={classes.ctaContainer}>
        <Button
          classes={{ root: classes.ctaButton }}
          onClick={() => {
            trackClickWithDescription(
              'bottom-sheet-confirm-reservation-reserve-now',
              'Button',
              draftReservation.venue?.name
            );
            handleReserve();
          }}
          disabled={shouldDisableSubmitButton}>
          {RESERVE_NOW_TEXT}
        </Button>
      </Container>
    </div>
  );
};

ConfirmReservation.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onDietEdit: PropTypes.func.isRequired,
  onEditPaymentMethod: PropTypes.func.isRequired,
  onNoAvailabilityLinkClick: PropTypes.func.isRequired,
};
