const RESERVATION_PAGE_BACK_ROUTE = 'reservationPageBackRoute';
const PERMALINK_ROUTE = 'permaLinkPageRoute';
const PERMALINK_SLUG = 'permalinkSlug';
const PERMALINK_AFTER_LOGIN = 'permalinkAfterLogin';
const RESERVATION_ROUTE = 'reservationRoute';
const RESERVATION_SOURCE = 'reservationSource';

export const setReservationPageBackRoute = (path) =>
  sessionStorage.setItem(RESERVATION_PAGE_BACK_ROUTE, path);
export const getReservationPageBackRoute = () =>
  sessionStorage.getItem(RESERVATION_PAGE_BACK_ROUTE);
export const clearReservationPageBackRoute = () =>
  sessionStorage.removeItem(RESERVATION_PAGE_BACK_ROUTE);

export const setPermalinkRouteRedirect = (path) =>
  sessionStorage.setItem(PERMALINK_ROUTE, path);
export const getPermalinkRouteRedirect = () =>
  sessionStorage.getItem(PERMALINK_ROUTE);
export const clearPermalinkRouteRedirect = () =>
  sessionStorage.removeItem(PERMALINK_ROUTE);

export const setPermalinkSlug = (slug) =>
  sessionStorage.setItem(PERMALINK_SLUG, slug);
export const getPermalinkSlug = () => sessionStorage.getItem(PERMALINK_SLUG);
export const clearPermalinkSlug = () =>
  sessionStorage.removeItem(PERMALINK_SLUG);

export const setPermalinkLogin = (path) => sessionStorage.setItem(PERMALINK_AFTER_LOGIN, path);
export const getPermalinkLogin = () => sessionStorage.getItem(PERMALINK_AFTER_LOGIN);
export const clearPermalinkLogin = () => sessionStorage.removeItem(PERMALINK_AFTER_LOGIN);

export const setReservationRoute = (path) => sessionStorage.setItem(RESERVATION_ROUTE, path);
export const getReservationRoute = () => sessionStorage.getItem(RESERVATION_ROUTE);
export const clearReservationRoute = () => sessionStorage.removeItem(RESERVATION_ROUTE);

export const setReservationSource = (source) => sessionStorage.setItem(RESERVATION_SOURCE, source);
export const getReservationSource = () => sessionStorage.getItem(RESERVATION_SOURCE);
export const clearReservationSource = () => sessionStorage.removeItem(RESERVATION_SOURCE);
