import React, { useState } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { HOMEPAGE_LINK_ALT_TEXT } from '../../../assets/copy';
import { ReactComponent as Logo } from '../../../assets/icons/logo.svg';
import { trackClick } from '../../../utils/useOneTag';
import MarketSelector from '../MarketSelector';
import MenuAnimation from '../../animation/MenuAnimation';
import SearchOverlay from '../../../pages/search/SearchOverlay';
import SearchButton, { SearchBarButton } from '../../core/AppBar/SearchButton';
import UserNavigationDropdown from '../../core/AppBar/UserNavigationDropdown';
import { DESKTOP_NAVIGATION_LINKS } from '../../../utils/constants/Links';
import NavigationLink from '../../core/NavigationLink';
import MobileMenuButton from '../../core/AppBar/MobileMenuButton';
import { useDispatch, useSelector } from 'react-redux';
import { actionSetNavigationMenuVisible } from '../../../store/AppData/AppDataAction';
import MobileMenu from '../../core/AppBar/MobileMenu';
import { useViewport } from '../../../utils/useViewport';
import { BREAKPOINT_VALUES } from '../../../utils/constants/Breakpoints';
import clsx from 'clsx';
import { focusVisible } from '../../../utils/ada/contrastStyles';
import { NAVIGATION_PREMIER_LOCATIONS } from '../../../assets/copy';

const useStyles = makeStyles((theme) => ({
  root: {},
  header: {
    width: '100%',
    height: ({ variant }) => (variant === 'compact' ? 54 : 96),
    backgroundColor: theme.palette.background.white,
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: theme.breakpoints.values.xxl,
    margin: '0 auto',
    padding: theme.spacing(0, 1),
    height: '100%',
    [theme.breakpoints.up('md')]: {
      gap: theme.spacing(3),
      padding: theme.spacing(0, 3),
    },
  },
  logoButton: {
    ...focusVisible(),
    boxSizing: 'border-box',
    padding: theme.spacing(1),
    borderRadius: 2,
  },
  logo: { width: 105, minWidth: 105 },
  section: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  navLinkGap: {
    gap: theme.spacing(4),
  },
}));

const BREAKPOINT = BREAKPOINT_VALUES.xxl;

export const NavBar = ({ classes: classesOverride, variant = 'default' }) => {
  const classes = useStyles({ classes: classesOverride, variant });
  const history = useHistory();
  const { width } = useViewport();
  const dispatch = useDispatch();
  const { overlayVisible } = useSelector((state) => state.search);
  const [menuVisible, setMenuVisible] = useState(false);

  const handleClick = (event) => {
    trackClick(event, 'app-bar-logo', 'Link');
    history.push('/');
  };
  const handleNavLinkClick = (event, dataCy) => {
    trackClick(event, dataCy, 'Link');
  };
  const toggleMenu = (shouldShowMenu = !menuVisible) => {
    setMenuVisible(shouldShowMenu || !menuVisible);
    dispatch(actionSetNavigationMenuVisible(shouldShowMenu || !menuVisible));
  };

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <div className={classes.container}>
          <div className={clsx(classes.section, classes.navLinkGap)}>
            <Button
              disableRipple
              className={classes.logoButton}
              onClick={handleClick}
              data-cy="app-bar-logo"
              aria-label={HOMEPAGE_LINK_ALT_TEXT}>
              <Logo className={classes.logo} />
            </Button>
            {variant === 'default' &&
              DESKTOP_NAVIGATION_LINKS.map(
                ({ label, path, dataCy }) =>
                  label !== NAVIGATION_PREMIER_LOCATIONS && (
                    <NavigationLink
                      key={path}
                      label={label}
                      path={path}
                      variant={'small2Normal'}
                      data-cy={dataCy}
                      onClick={(e) => {
                        handleNavLinkClick(e, dataCy);
                      }}
                    />
                  )
              )}
          </div>

          <div
            className={clsx(classes.section, {
              [classes.navLinkGap]: width > BREAKPOINT,
            })}>
            {!overlayVisible && <MarketSelector />}
            {variant === 'compact' || width < BREAKPOINT ? (
              <SearchButton menuVisible={menuVisible} />
            ) : (
              <SearchBarButton menuVisible={menuVisible} />
            )}

            {variant === 'compact' ? (
              <MobileMenuButton
                handlePress={() => toggleMenu(true)}
                menuVisible={menuVisible}
              />
            ) : (
              <UserNavigationDropdown />
            )}
          </div>
        </div>
      </header>
      <MenuAnimation isOpen={menuVisible}>
        <MobileMenu close={() => toggleMenu(false)} isOpen={menuVisible} />
      </MenuAnimation>
      {!menuVisible && (
        <MenuAnimation isOpen={overlayVisible}>
          <SearchOverlay />
        </MenuAnimation>
      )}
    </div>
  );
};

NavBar.propTypes = {
  classes: PropTypes.object,
  variant: PropTypes.oneOf(['default', 'compact']),
};

export default NavBar;
