import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  MENU_ALT_TEXT,
  NAVIGATION_SHOW_ALT_TEXT,
  EXPANDED_TEXT,
  COLLAPSED_TEXT,
} from '../../../assets/copy';
import { ReactComponent as MenuIcon } from '../../../assets/icons/grv-menu.svg';
import { focusVisible } from '../../../utils/ada/contrastStyles';

const useStyles = makeStyles(() => ({
  root: {
    ...focusVisible(),
  },
}));

const MobileMenuButton = ({ handlePress, menuVisible }) => {
  const classes = useStyles();
  const { overlayVisible } = useSelector((state) => state.search);
  const STATUS = menuVisible ? EXPANDED_TEXT : COLLAPSED_TEXT;

  if (overlayVisible) return null;

  return (
    <IconButton
      className={classes.root}
      autoFocus={false}
      aria-label={`${NAVIGATION_SHOW_ALT_TEXT} ${STATUS}`}
      onClick={handlePress}
      disableFocusRipple>
      <MenuIcon aria-hidden="true" alt={MENU_ALT_TEXT} />
    </IconButton>
  );
};

export default MobileMenuButton;
